import React from 'react';
import task_il_tg from '../custom_icons/task_il_tg.svg'
import chevron_next_insta_android from '../custom_icons/chevron_next.svg'
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import BaseEarnTask from './BaseEarnTask';
import { getRequestParams } from './utils_android/CookieHelper';
import { getRequestUrl, getHttpParams } from '../../utils/Utils';

var strings = getLocalizedStrings()
class CustomTaskTelegram extends BaseEarnTask {
    constructor(props){
        super(props)
        this.state = {
            currentDescription : ""
        }
     
        //this.toogleFirstTimeFollow = this.toogleFirstTimeFollow.bind(this);
    }

    doTask = () => {
        this.startTaskTime = new Date().getTime()
        this.openUrl("https://t.me/instarlike", 0)
    }
    onResume(){
        if (this.startTaskTime != -1){
            this.addCoins()
        }
        console.log("onResume CustomTaskTelegram")
    }
    skip = () => {
        this.props.loadNextTask()
    }
    addCoins = () => {
            var params = getRequestParams();
            fetch(getRequestUrl() + "checkFreeCoins", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                this.props.loadNextTask()
                this.startTaskTime = -1
                console.log("checkFreeCoins" + JSON.stringify(responseData))
            })
    }
    render() {
        return (
            <div className="rate-content__item">
                    <div className="followers_container_appeal">
                        <img className="placeholder_task_custom task_tg"
                            src={task_il_tg} alt=""/>
                            <p className="custom_task_link_title task_tg_text">{strings.TG_TASK}<br/>{strings.TG_TASK_2}</p>
                        
                        <img onClick={this.skip} src={chevron_next_insta_android} className="arrows__item arrows__item_next arrow_item_follower hover"></img>
                    </div>

                    
                    <div className="rate-content__actions">                        
                        <div onClick={this.doTask} className="btn-follow top_10">
                            {strings.FOLLOW}
                        </div>                        
                        <div className="coins_rub_lashka rub_plashka">
                            <span className="subscribe_follow_reward left_4 subscribe_follow_reward_custom earn_by_words_price">{strings.TG_TASK_PRICE}</span>
                        </div>
           
                    </div>
                    
                </div>
        );
    }
}
export default CustomTaskTelegram;
