
  import React from 'react';
  import { Link, animateScroll as scroll } from "react-scroll";
  import  close_menu from '../landing/icons/close_menu.svg'
  import  logo_instarlike from '../landing/icons/logo_instarlike.svg'
  import boom from '../landing/icons/boom.svg'
  import instagram_logo from '../landing/icons/instagram_logo.svg'
  import vk_icon from '../landing/icons/vk_icon.svg'
  import icon_stories from '../custom_views/custom_icons/icon_stories.svg'
  import social_telegram from '../landing/icons/social_telegram.svg'

import { getLocalizedLandingStrings } from './LocalizedLanding';

  var strings = getLocalizedLandingStrings()
  
  class MobileLandingMenu extends React.Component {
      constructor(props){
          super(props)
      }
      openAdvice = () => {
        window.location.href = 'http://blog.instarlike.com'
      }
      render(){
              return (
                <div className="navbar-opened-plashka-mobile">
                    <div onClick={this.props.closeMenu} className="close_menu_container">
                      <img className="close_menu" src={close_menu}/>
                    </div>
                    <img className="menu_logo bot_32_menu" src={logo_instarlike}/>
                    <div  className="about-us-trigger navbar-text-mobile m_bot_menu bot_32_menu">
                        <Link onClick={this.props.closeMenu} smooth={"true"} to="about_us" >{strings.ABOUT_US}</Link>
                    </div>
                    <div  className="how-it-works-trigger navbar-text-mobile m_bot_menu bot_32_menu">
                        <Link onClick={this.props.closeMenu} smooth={"true"} to="how-it-works" >{strings.HOW_IT_WORKS}</Link>
                    </div>
                    <div  className="reviews-trigger navbar-text-mobile m_bot_menu bot_32_menu">
                       <Link onClick={this.props.closeMenu} smooth={"true"} to="reviews" >{strings.REVIEWS}</Link>
                    </div>
                    <div className="menu_line bot_32_menu"/>
{/*                     
                    <p onClick={this.props.openImprovement} className="black_navbar_text hover bot_32_menu">{strings.BLACK_NAVBAR_1}</p>
                    <p onClick={this.props.howImprove} className="black_navbar_text bot_32_menu hover">{strings.BLACK_NAVBAR_2}</p>
                    <p onClick={this.props.partner} className="black_navbar_text bot_32_menu hover">{strings.BLACK_NAVBAR_3}</p> */}

                    {/* <div className="flex"> 
                        <p onClick={this.props.openStories} className="black_navbar_text bot_32_menu hover">{strings.STORIES_MENU}</p>
                        <img className="menu_icon_stories_land" src={icon_stories}/>
                    </div> */}
                    <div className="flex">
                        <p onClick={this.props.other} className="black_navbar_text hover bot_32_menu">{strings.BLACK_NAVBAR_4}</p>
                        <img className="left_m_8_menu bot_32_menu" src={boom}/>
                    </div>
                    <div className="flex">
                        <div onClick={this.props.openInsta} className="footer_icons hover">
                          <img className="footer_icon_height" src={instagram_logo}/>
                        </div>
                        <div onClick={this.props.openVK} className="footer_icons hover">
                           <img className="footer_icon_height" src={vk_icon}/>
                        </div>
                        <div onClick={this.props.openTG} className="hover">
                           <img className="footer_icon_height" src={social_telegram}/>
                        </div>
                    </div>  
                    <p onClick={this.props.openAuth} className="menu_btn">{strings.PROMOTE_MY_ACCOUNT}</p>  
                    {/* <p onClick={this.openAdvice} className="advices-trigger navbar-text-mobile bot_0 m_bot_menu">{strings.ADVICES_WRAP}</p> */}
                </div>
              ); 
          
          
      }
  }
  export default MobileLandingMenu;
  