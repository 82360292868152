import React from 'react';
import empty_video_img_insta from './insta_icons_tasks/empty_video_img_insta.svg'
import video_watch_insta_android from '../custom_icons/video_watch.svg'
import video_insta_android from './insta_icons_tasks/video_insta_android.svg'
import chevron_next_insta_android from '../custom_icons/chevron_next.svg'
import BaseEarnTask from './BaseEarnTask';
import qs from 'querystring'
import {getRequestParams} from './utils_android/CookieHelper'
import { showAndroidToast } from './utils_android/AndroidMethods';
import { ReCaptcha } from 'react-recaptcha-v3'
import { getTimestampToken, getHttpParams, getRequestUrl } from '../../utils/Utils';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
var strings = getLocalizedStrings()
class ExtraView extends BaseEarnTask {
    constructor(props){
        super(props)
        this.state = {
            currentDescription : "",
            isUpdateCaptcha : false,
            isShowError : false,
            isImgError : false
        }
        this.startTaskTime = -1
        window.extraView = this
        this.toogleImgError = this.toogleImgError.bind(this);
    }
   
    onResume(){
        this.setState({isUpdateCaptcha : true})
    }
    verifyCallback = (recaptchaToken) => {
        this.setState({isUpdateCaptcha : false})

        if (this.startTaskTime != -1){
            this.setState({isShowError : false})
            var currentTime = new Date().getTime()
            if (currentTime - 300 >= this.startTaskTime){
                this.props.showLoader()
                this.instaViewVideo(recaptchaToken)
            } else {
                this.setState({isShowError : true})
            }
        }
    }
    doTask = () => {
        this.startTaskTime = new Date().getTime()
        this.openUrl(this.props.currentExtraOrder.insta_url, 3)
    }
    toogleImgError(){
        this.setState({isImgError : true})
    }
    instaViewVideo = (recaptchaToken) => {
        var params = getRequestParams();
        params.timestamp = recaptchaToken
        params._id = this.props.currentExtraOrder.order._id;
        fetch(getRequestUrl() + "instaViewVideo", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                this.props.loadNextOrder()
            } else {
                this.errorTask()
            }
            this.startTaskTime = -1
            console.log("instaComment" + JSON.stringify(responseData))
        })
    }
    skip = () => {
        this.skipOrder(false, this.props.currentExtraOrder.order._id)
    }
  
    render() {
        return (
            <div className="rate-content__item">
                    <div className="photo_task_container">
                        <img width="64" height="64" className="placeholder_task icon_64"
                            src={empty_video_img_insta} alt=""/>
                        <img style={this.state.isImgError ? {opacity : '0'} : {}} onError={this.toogleImgError} className="image_task_holder" src={this.props.currentExtraOrder.userInfo ? this.getSource(this.props.currentExtraOrder.userInfo.items[0]) : this.props.currentExtraOrder.order.photo_url}
                            alt="" width="360" height="360"/>
                        <img onClick={this.skip} src={chevron_next_insta_android} className="arrows__item arrows__item_next arrow_item_follower"></img>
                        <img src={video_insta_android} className="rate-content__video-icon"></img>
                        {/* <p onClick={this.appealClicked} className="appeal_text appeal_text_bg">{strings.APPEAL}</p> */}
                    </div>


                    <div className="rate-content__actions">
                        {this.state.isShowError && <p className="block_account">{strings.WATCH_MIN_7}</p>}
                        <div onClick={this.doTask} className="btn-follow top_10">
                            <img className="btn_task_icon" src={video_watch_insta_android} alt=""/>
                            {strings.WATCH_VIDEO}
                        </div>
                        <div className="coins_rub_lashka rub_plashka">
                            <span className="subscribe_follow_reward right_4">+{this.props.reward} {strings.COIN_2}</span>
                            <span className="subscribe_follow_reward left_4">+{this.props.currentExtraOrder.earn_money}₽</span>
                        </div>
                        
                    </div>
                    {this.state.isUpdateCaptcha && <ReCaptcha
                        sitekey={getTimestampToken()}
                        action='homepage'
                        verifyCallback={this.verifyCallback}/>
                    } 
                </div>
        );
    }
}
export default ExtraView;
