import React from 'react';
import empty_bonus from '../custom_icons/empty_bonus.svg'
import chevron_next_insta_android from '../custom_icons/chevron_next.svg'
import { getLocalizedStrings } from '../../utils/LocalizedStrings';

var strings = getLocalizedStrings()
class EmptyTaskBonus extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            currentDescription : ""
        }
     
        //this.toogleFirstTimeFollow = this.toogleFirstTimeFollow.bind(this);
    }

    doTask = () => {
        window.open("http://appmob.ru", '_blank')
    }
   
    skip = () => {
        this.props.loadNextTask()
    }
    
    render() {
        return (
            <div className="rate-content__item">
                    <div className="followers_container_appeal">
                        <img style={{height: '36px', width : '36px'}} width="36" height="36" className="placeholder_task_custom"
                            src={empty_bonus} alt=""/>
                            <p style={{fontSize : '12px', fontFamily : 'OpenSans-Bold'}} className="custom_task_link_title">{strings.EMPTY_TASK_BONUS}<br/>{strings.EMPTY_TASK_BONUS_2}</p>
                            <p style={{fontSize : '12px', marginLeft : '-10px', width : 'calc(100% + 20px)'}} className="custom_task_link_title custom_task_link_text">{strings.EMPTY_TASK_BONUS_3}</p>
                        
                        {/* <img onClick={this.skip} src={chevron_next_insta_android} className="arrows__item arrows__item_next arrow_item_follower hover"></img> */}
                    </div>

                    
                    <div className="rate-content__actions">                        
                        <div onClick={this.doTask} className="btn-follow top_10">
                            {strings.EMPTY_TASK_BONUS_BTN}
                        </div>                        
                       
           
                    </div>
                    
                </div>
        );
    }
}
export default EmptyTaskBonus;
