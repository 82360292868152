import React from 'react';
import { getLocalizedStrings } from './utils/LocalizedStrings';
import '../css_android/work.css'
import Withdrow from './custom_views/tooltips/Withdrow';
import person from "../pages_android/custom_views/custom_icons/person.svg"
import { getUserId, getRequestParams } from './custom_views/insta_custom_views/utils_android/CookieHelper';
import { getRequestUrl, getHttpParams, declension } from './utils/Utils';
import SessionPromotionTooltip from './custom_views/tooltips/SessionPromotionTooltip';
import WorkLandscape from './custom_views/WorkLandscape';
var strings = getLocalizedStrings()
class Work extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            promoId : ("https://instarlike.com/?promo_id=" + getUserId()),
            isShowWithdrow : false,
            isShowSessionError : false,
            friendsInvitedCount : 0,
            declentionReferal : strings.COIN_5,
            referals_coins : 0,
            isMobile : (window.screen.width <= 768)
        }
        this.toogleWithdrow = this.toogleWithdrow.bind(this);
        this.openMore = this.openMore.bind(this);
        this.copy = this.copy.bind(this);
    }
    copy(){
        var textField = document.createElement('textarea')
        textField.innerText = this.state.promoId
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }
   
    toogleWithdrow(){
        console.log("toogleWithdrow")
        this.setState({isShowWithdrow : !this.state.isShowWithdrow})
    }
    getUser = () => {
        var params = getRequestParams();
        fetch(getRequestUrl() + "getMyCoins", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
           var friendsInvited = responseData.referals_coins/20
           this.setState({friendsInvitedCount : friendsInvited, referals_coins : responseData.referals_coins, declentionReferal : declension(responseData.referals_coins, strings.COIN, strings.COIN_2, strings.COIN_5)})
        })
    }
    showSessionError = () => {
        this.setState({isShowSessionError : true})
    }
    openMore(){
        window.open('https://blog.instarlike.com/2020/04/16/partnerskaya-programma-dlya-zarabotka/', '_blank');
    }
    componentDidMount(){
        this.getUser()
    }
    render(){
      
            return (
                <div style={this.state.isShowWithdrow ? {minHeight : 'calc(100vh + 400px)'} : {}} className="promotion-page">
                    {this.state.isShowSessionError && <SessionPromotionTooltip tooltipText={strings.DO_TASK_TO_EARN} acceptText={strings.PROMOTION_ACCEPT_EARN}/>}
                    <h2 className="h1 title-bottom-border">{strings.MENU_4}</h2>
                    <div className="invite-friends">
                        <div className="share-block share-block-mobile">
                            <p className="top_32_m">{strings.COPY_AND_SHARE}</p>
                            <div className="share-block__link">
                                <input className="share-block__input" type="text" value={this.state.promoId} readOnly/>
                                <button onClick={this.copy} className="btn share-block__copy-btn">{strings.COPY}</button>
                            </div>
                            <p className="share_text_bot top_m_16">{strings.COPY_AND_SHARE_2_NEW}</p>
                            {!this.state.isMobile ? <WorkLandscape count_money_r={this.props.count_money_r} showSessionError={this.showSessionError} toogleWithdrow={this.toogleWithdrow} isShowWithdrow={this.state.isShowWithdrow} referals_coins={this.state.referals_coins} declentionReferal={this.state.declentionReferal} friendsInvitedCount={this.state.friendsInvitedCount}/> :
                            <div className="invite-friends-content">
                                <div className="invite_bonus_yellow">
                                    <div>
                                        <p className="invite_bonus_text">30%</p>
                                        <p  style={{paddingTop : "6px"}} className="invite_bonus_text_little">{strings.PRICE_OF}<br/>{strings.ORDERS}</p>
                                    </div>
                                    <p className="invite_bonus_text ivite_bonus_center_text">+</p>

                                    <div>
                                        <p className="invite_bonus_text">20</p>
                                        <p  style={{paddingTop : "6px"}} className="invite_bonus_text_little">{strings.COIN_5}</p>
                                    </div>
                                </div>
                                <p className="share_text_bot top_m_16">{strings.NEW_USER_GET}
                                <strong> {strings.COINS_20}</strong> {strings.COINS_AFTER}</p>
                                <p className="share_text_bot top_m_24_s">{strings.MORE_INFO_REF}
                                <strong onClick={this.openMore} className="ref_more hover"> {strings.MORE_INFO_REF_2}</strong></p>
                                <div className="invite-bonus bonus-left pad_0">
                                    <div className="inner_bonus">
                                        <h3 className="h3 invite-bonus__heading">{strings.YOU_EARNED}</h3>
                                        <p className="invite-bonus__heading bonus_rub">{parseFloat(this.props.count_money_r).toFixed(2)}₽</p>
                                        <span className="tooltip rate-content__tooltip left_m_0">
                                            <p onClick={this.toogleWithdrow} className="btn-get-money">{strings.WITHDROW}</p>
                                            {this.state.isShowWithdrow && <Withdrow showSessionError={this.showSessionError} count_money_r={this.props.count_money_r} toogleWithdrow={this.toogleWithdrow}/>}
                                            <p className="top_m_10_s">{strings.LIMIT_50}</p>
                                        </span>
                                    </div>
                                    <div className="accepted_invites">
                                        <div className="inner_bonus">
                                            <h3 className="h3 invite-bonus__heading">{strings.INVITE_ACCEPTED}</h3>
                                            <div className="invite-bonus__heading bonus_rub">{this.state.friendsInvitedCount} 
                                                <img className="person_img" src={person} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="invite_bonus_yellow inner_bonus block_work">
                                    <h3 className="h3 invite-bonus__heading line_h_23">
                                        {strings.TOP_5_EARNINGS}<br/>{strings.INSTARLIKE_PARTNERS}
                                    </h3>
                                    <p style={{lineHeight : '30px'}} className="money_get_intarlike line_h_30">
                                        18 350₽<br/>
                                        13 200₽<br/>
                                        10 001₽<br/>
                                        9 800₽<br/>
                                        1 800₽
                                    </p>
                                </div>
                                
                            </div>}
                        </div>
                    </div>
                    
                    
                </div>
            ); 
        
        
    }
}
export default Work;
