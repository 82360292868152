import React from 'react';
import DropdownItem from './DropdownItem';
import Loader from './utils_android/LoaderAndroid';
import AuthLoader from '../loaders/AuthLoader';
class AuthDropdown extends React.Component {
    constructor(props){
        super(props)
        
    }
    
    copyClicked = () => {
    }
    render() {
        return (
            <div className="select__wrapper">
                <div className="select__list">  
                    {this.props.isSearching ? <AuthLoader /> : this.props.users.map((item, index) => (
                        <DropdownItem userPicked={this.props.userPicked} user={item} key={index}/>
                    ))}
                </div>
                <i className="tooltip__arrow select__arrow"></i>
            </div>
        );
    }
}
export default AuthDropdown;