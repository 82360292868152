/* eslint-disable eqeqeq */
import React from 'react';
import ReactDOM from 'react-dom';
import Menu from './components_android/MenuAndroid'
import Navbar from './components_android/NavbarAndroid'
import './css_android/style_android.css';
import {
    BrowserRouter as BrowserRouter,
    Switch,
    Route
} from "react-router-dom";
import {getRequestParams, setPromoId, setLogin, getLogin, setSessionId} from './pages_android/custom_views/insta_custom_views/utils_android/CookieHelper'
import Auth from './pages_android/Auth';
import Earn from './pages_android/Earn';
import Policy from './pages_android/Policy';
import { getRequestUrl, getHttpParams } from './pages_android/utils/Utils';
import Promotion from './pages_android/Promotion';
import Work from './pages_android/Work';
import Shop from './pages_android/Shop';
import Landing from './pages_android/Landing';
import { getUrlParameter } from './pages_android/custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
import Agreement from './pages_android/Agreement';
import PaymentApp from './pages_android/PaymentApp';
import Social from './pages_android/Social';

class Application extends React.Component {
   
    constructor(){
        super()
        this.state = {
            count_money_r : 0.0,
            count_coins : 0,
            nick_name : "",
            photo_url : "",
            email : "",
            google_user_id : "",
            info : {},
            done : false,
            isUpmob : false,
            ref_id : "",
            avatar : "",
            isShowMenu : !(window.location.href.includes('/auth') || window.location.href.includes('/policy'))
        }
        setSessionId()
        var promo = getUrlParameter("promo_id")
        if (promo != null && promo != undefined && promo != ""){
            setPromoId(promo)
        }
        var login = getLogin()
        if (login == undefined || login == null) {
            if (getUrlParameter('username') != undefined) {
                setLogin(getUrlParameter('username'))
            }
            if (!document.location.pathname.includes("auth") && document.location.pathname != "" && document.location.pathname != "/" && !document.location.pathname.includes("payment_app")){
                if (!document.location.pathname.includes("policy") && !document.location.pathname.includes("agreement")){
                    const urlUsername = getUrlParameter("username")
                    window.location.href = '/auth?isAddAccount=true' + ((urlUsername != null && urlUsername != undefined && urlUsername != "") ? "&username=" + urlUsername : "")
                }
                
            }
        }
        console.log("location = " + window.location.href)
        if (window.VK != undefined){
            window.VK.Widgets.CommunityMessages("vk_community_messages", 183469749, {disableExpandChatSound: "1",tooltipButtonText: "Есть вопрос?",disableButtonTooltip: "1"})
        }
    }
    
   
    componentDidMount(){
        if (!document.location.pathname.includes("payment_app")){
            this.getMyInfo()
        }
    }
    getMyInfo(){
        this.setState({done : true})
        var params = getRequestParams()
        fetch(getRequestUrl() + "getMyCoins", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.count_coins != undefined){
                this.setState({count_coins : responseData.count_coins, count_money_r : responseData.count_money_r})
            }
        })
    }
    setAvatar = (avatar) => {
        this.setState({avatar : avatar})
    }
    updateMoney = (money, coins) => {
        var countMoney = this.state.count_money_r + parseFloat(money)
        this.setState({count_money_r : countMoney, count_coins : (this.state.count_coins + coins)})
    }
    setMoney = (money, coins) => {
        this.setState({count_money_r : money, count_coins : coins})
    }
    render() {
        if (document.location.pathname == "/" || document.location.pathname == ""){
            return(
                <Landing/>
            )
        } else if (document.location.pathname.includes("payment_app")){
            return(
                <PaymentApp/>
            )
        } else {
            return (
                <div>
                    <div className="app">
                    <aside style={(document.location.pathname == "/auth" || document.location.pathname == "/policy" || document.location.pathname == "/agreement") ? {display : 'none'} : {}}  className = "aside">
                            <Menu avatar={this.state.avatar} count_money_r={this.state.count_money_r} name={this.state.nick_name} photo={this.state.photo_url} email={this.state.email} google_user_id={this.state.google_user_id}/> 
                        </aside>
                        <div className = "main">
                            {(document.location.pathname != "/policy" && document.location.pathname != "/agreement") && <Navbar count_coins={this.state.count_coins} isShowMenu={this.state.isShowMenu} count_money_r={this.state.count_money_r}/>}
                            <section>
                                <main>
                                    <Switch>
                                    
                                        <Route path="/auth">
                                            <Auth/>
                                        </Route>
                                        <Route path="/earn">
                                            <Earn setMoney={this.setMoney}/>
                                        </Route>
                                        <Route path="/social">
                                            <Social count_money_r={this.state.count_money_r} count_coins={this.state.count_coins} setMoney={this.setMoney}/>
                                        </Route>
                                        <Route path="/policy">
                                            <Policy />
                                        </Route>
                                        <Route path="/promotion">
                                            <Promotion setAvatar={this.setAvatar} updateMoney={this.updateMoney} count_money_r={this.state.count_money_r} count_coins={this.state.count_coins}/>
                                        </Route>
                                        <Route path="/work">
                                            <Work updateMoney={this.updateMoney} count_money_r={this.state.count_money_r} count_coins={this.state.count_coins}/>
                                        </Route>
                                        <Route path="/up-balance">
                                            <Shop count_money_r={this.state.count_money_r}/>
                                        </Route>
                                        <Route path="/agreement">
                                            <Agreement />
                                        </Route>
                                    
                                    </Switch>
                                </main>
                            </section>
                        </div>
                        {/* <p style={{margin : '16px'}}>Ведутся технические работы. Возвращайтесь позже.</p> */}
                    
                    </div>
                    <div id="vk_community_messages"></div>
                </div>
            );
        }
    }
}

ReactDOM.render(
    <BrowserRouter>
        <Application/>
    </BrowserRouter>,
    document.getElementById('root')
);

