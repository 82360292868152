import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import no_photo from '../custom_icons/no-photo.svg'
var strings = getLocalizedStrings()
class NoPhotos extends React.Component {
    constructor(props){
        super(props)

    }
    
    render(){
      
            return (
                <section ng-style="noPhotosStyle" className="account-content no_photos">
                    <div className="account-content__no-photo">
                        <img src={no_photo}/>
                        <span className="account-content__no-photo-text">{strings.NO_PHOTOS}</span>
                    </div>
                </section>
            ); 
        
        
    }
}
export default NoPhotos;
