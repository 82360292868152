import React from 'react';
import { getLocalizedLandingStrings } from './LocalizedLanding';
  
    var strings = getLocalizedLandingStrings()
    
    class ReviewPlashka extends React.Component {
        constructor(props){
            super(props)
        }
        
        render(){
                return (
                    <div className="reviews_plashka">
                        <p className="top-text-plashka-0">{this.props.name}</p>
                        <p className="regular-text-plashka">{this.props.text}</p>
                    </div>
                ); 
            
            
        }
    }
    export default ReviewPlashka;

