/* eslint-disable no-useless-constructor */
/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import NewBuyCoinsBtn from './NewBuyCoinsBtn';
import { calcStandartPrice, calcCoins } from './ShopHelper';
import { getIsTagsEuro, getUrlParameter } from '../../utils/Utils';
var strings = getLocalizedStrings()

class NewDesignShopButtons extends React.Component {
    constructor(props){
        super(props)
    }
    
    render(){
        const isTagsEuro = getIsTagsEuro()
        return (
            <div className="flex buy_container">
                <NewBuyCoinsBtn type={0} buyCoins={this.props.buyCoins} coins={'500'} coinsCount={500} fullPrice={calcStandartPrice(500)} realPrice={calcCoins(500)}/>
                <NewBuyCoinsBtn type={1} buyCoins={this.props.buyCoins} coins={'1000'} coinsCount={1000} fullPrice={calcStandartPrice(1000)} realPrice={calcCoins(1000)}/>
                <NewBuyCoinsBtn type={2} buyCoins={this.props.buyCoins} coins={'5000'} coinsCount={5000} fullPrice={calcStandartPrice(5000)} realPrice={calcCoins(5000)}/>
            </div>
        ); 
        
        
    }
}
export default NewDesignShopButtons;
