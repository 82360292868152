import React from 'react';
class Policy extends React.Component {
    constructor(props){
        super(props)
       
    }
   
    render() {
        return (
           
            <div className="m_16">
                <h1>Политика обработки персональных данных</h1><br />
                <p>Политика в отношении обработки персональных данных<br />
                1. Общие положения<br />
                Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. No152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных<br />
                данных Инстарлайк (далее – Оператор).<br />
                ● Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.<br />
                ● Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта http://instarlike.com.<br />
                2. Основные понятия, используемые в Политике<br />
                ● Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;<br />
                ● Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);<br />
                ● Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу http://instarlike.com;<br />
                ● Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;<br />
                ● Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;<br />
                ● Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ),</p>

                <p>обезличивание, блокирование, удаление, уничтожение персональных<br />
                данных;<br />
                ● Оператор – государственный орган, муниципальный орган, юридическое<br />
                или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;<br />
                ● Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб- сайта http://instarlike.com;<br />
                ● Пользователь – любой посетитель веб-сайта http://instarlike.com;<br />
                ● Предоставление персональных данных – действия, направленные на<br />
                раскрытие персональных данных определенному лицу или определенному<br />
                кругу лиц;<br />
                ● Распространение персональных данных – любые действия, направленные<br />
                на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;<br />
                ● Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;<br />
                ● Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители персональных данных.<br />
                3. Оператор может обрабатывать следующие персональные данные Пользователя<br />
                ● Фотографии;<br />
                ● логин Instagram;<br />
                ● Также на сайте происходит сбор и обработка обезличенных данных о<br />
                посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-<br />
                статистики (Яндекс Метрика и Гугл Аналитика и других).<br />
                ● Вышеперечисленные данные далее по тексту Политики объединены<br />
                общим понятием Персональные данные.</p>

                <p>4. Цели обработки персональных данных<br />
                ● Цель обработки персональных данных Пользователя — предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте.<br />
                ● Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты instarlikeme@gmail.com с пометкой «Отказ от уведомлениях о новых продуктах и услугах и специальных предложениях».<br />
                ● Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.<br />
                5. Правовые основания обработки персональных данных<br />
                ● Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте http://instarlike.com. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.<br />
                ● Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).<br />
                6. Порядок сбора, хранения, передачи и других видов обработки персональных данных<br />
                Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.<br />
                ● Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.<br />
                ● Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.<br />
                ● В случае выявления неточностей в персональных данных, Пользователь</p>

                <p>●<br />
                может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора instarlikeme@gmail.com с пометкой «Актуализация персональных данных».<br />
                ● Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператораinstarlikeme@gmail.com с пометкой «Отзыв согласия на обработку персональных данных».<br />
                7. Трансграничная передача персональных данных<br />
                ● Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.<br />
                ● Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.<br />
                8. Заключительные положения<br />
                ● Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты instarlikeme@gmail.com.<br />
                ● В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.<br />
                ● Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу http://instarlike.com/policy.<br />
                </p>
            </div>


        );
    }
}
export default Policy;