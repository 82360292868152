
  import React from 'react';
  import { getLocalizedLandingStrings } from './LocalizedLanding';
  
    var strings = getLocalizedLandingStrings()
    
    class EarnPlashka extends React.Component {
        constructor(props){
            super(props)
        }
        
        render(){
                return (
                    <div className="earn-plashka">
                        <h2 className="top-text-plashka">{this.props.text}</h2>
                        <p className="text-earn-plashka">{this.props.description}</p>
                        <div className="img-div-earn">
                            <img style={this.props.style ? this.props.style : {}} className="earn-img" src={this.props.image} title={this.props.imageTitle} alt={this.props.imageTitle}/>
                        </div>
                  </div>
                ); 
            
            
        }
    }
    export default EarnPlashka;
    