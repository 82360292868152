/* eslint-disable eqeqeq */
import { getIsIosApp, getIsTagsEuro, getUrlParameter } from "../../utils/Utils"

export const calcCoins = (enteredCoins) => {
    if (getIsIosApp()){
      return calcCoinsIOS(enteredCoins)
    }
    var standartPrice = Math.round(enteredCoins / 3)
    if (enteredCoins >= 100) {
      standartPrice = Math.round(enteredCoins / 4)
    }
    if (enteredCoins >= 500) {
      standartPrice = Math.round(enteredCoins / 5.05)
    }
    if (enteredCoins >= 1000) {
      standartPrice = Math.round(enteredCoins / 6.3)
    }
    if (enteredCoins >= 5000) {
      standartPrice = Math.round(enteredCoins / 7.16)
    }
    if (enteredCoins >= 10000) {
      standartPrice = Math.round(enteredCoins / 8)
    }
    return standartPrice
}
export const calcCoinsIOS = (enteredCoins) => {
    var standartPrice = Math.round(enteredCoins / 1.7)
    if (enteredCoins >= 100) {
      standartPrice = Math.round(enteredCoins / 1.7)
    }
    if (enteredCoins >= 500) {
      standartPrice = Math.round(enteredCoins / 2.79)
    }
    if (enteredCoins >= 1000) {
      standartPrice = Math.round(enteredCoins / 3.86)
    }
    if (enteredCoins >= 5000) {
      standartPrice = Math.round(enteredCoins / 5.56)
    }
    if (enteredCoins >= 10000) {
      standartPrice = Math.round(enteredCoins / 8)
    }
    return standartPrice
}
export const calcFakeCoinsIOS = (enteredCoins) => {
    var standartPrice = Math.round(enteredCoins / 1.5)
    if (enteredCoins >= 100) {
      standartPrice = Math.round(enteredCoins / 1.01)
    }
    if (enteredCoins >= 500) {
      standartPrice = Math.round(enteredCoins / 2.51)
    }
    if (enteredCoins >= 1000) {
      standartPrice = Math.round(enteredCoins / 3.35)
    }
    if (enteredCoins >= 5000) {
      standartPrice = Math.round(enteredCoins / 4.17)
    }
    if (enteredCoins >= 10000) {
      standartPrice = Math.round(enteredCoins / 5.85)
    }
    return standartPrice
}
export const getTagsEuroPrices = (enteredCoins) => {
    var standartPrice = 30
    if (enteredCoins == 100) {
        standartPrice = 30
    }
    if (enteredCoins == 500) {
      standartPrice = 129
    }
    if (enteredCoins == 1000) {
      standartPrice = 239
    }
    if (enteredCoins == 5000) {
      standartPrice = 999
    }
    if (enteredCoins == 10000) {
      standartPrice = 1790
    }
    if (enteredCoins == 50000) {
      standartPrice = 6490
    }
    return standartPrice
}
export const calcStandartPrice = (enteredCoins) => {
    var standartPrice = Math.round(enteredCoins / 3)
    const isTagsEuro = getIsTagsEuro()
    const isIosApp = getIsIosApp()
    if (isIosApp){
      return calcFakeCoinsIOS(enteredCoins)
    }
    if (isTagsEuro){
        standartPrice = getTagsEuroPrices(enteredCoins)
    }
    return standartPrice
}