import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import heart_fine from '../custom_icons/heart_fine.svg'
import { declension } from '../../utils/Utils';

var strings = getLocalizedStrings()
class FineDialog extends React.Component {
    constructor(props){
        super(props)

    }
    closeTooltip = () =>{
        this.props.toogleFineDialog()
    }
    render(){
      
            return (
                <div className="fine_dialog_background">
                    <div className="fine_dialog">
                        <img className="fine_icon" src={heart_fine}/>
                        <p className="fine_title">{strings.FINE_TITLE}</p>
                        <p className="fine_text">{strings.FINE_TEXT} <span style={{fontFamily : 'OpenSans-Bold'}}>{this.props.coinsCount} {declension(this.props.coinsCount, strings.COIN, strings.COIN_2, strings.COIN_5)}.</span></p>
                        <p className="fine_text">{strings.FINE_TEXT_2}</p>
                        <p onClick={this.closeTooltip} className="fine_ok hover">{strings.GOT_IT}</p>
                    </div>
                </div>
                    
            ); 
        
        
    }
}
export default FineDialog;
