/* eslint-disable eqeqeq */
import React from 'react';
import {getRequestParams, getLogin, saveInstaCookie, updateLoginData} from './custom_views/insta_custom_views/utils_android/CookieHelper'
import {getRequestUrl, getHttpParams, getUrlParameter } from './utils/Utils';
import { getLocalizedStrings } from './utils/LocalizedStrings';
import DiscountBanner from './custom_views/tooltips/DiscountBanner';
import '../pages_android/custom_views/promotion/promotion.css'
import BuyFollowers from './custom_views/promotion/BuyFollowers';
import LoaderEarn from './custom_views/insta_custom_views/utils_android/LoaderEarnAndroid';
import PhotoItem from './list_items/PhotoItem';
import NoPhotos from './custom_views/empty_views/NoPhotos';
import Toast from './custom_views/insta_custom_views/Toast';
import { Route } from "react-router-dom";
import MobileOrder from './custom_views/promotion/MobileOrder';
import { getIsDesktop } from './utils/PhotoItemHelper';
import { Link } from 'react-router-dom/cjs/react-router-dom';

var strings = getLocalizedStrings()
class Promotion extends React.Component {
    constructor(props){
        super(props)
        
        this.state = {
           isShowDiscountBanner : false,
           user : {},
           coinsPrice : 0,
           notEnoughtCoins : 0,
           allProgressCoins : 0,
           isPrivate : false,
           isShowSessionError : false,
           isShowEmptyPhotos : false,
           photos : [],
           reels : [],
           stories : [],
           isLoading : true,
           isShowSessionBanner : false,
           isShowRequestError : false,
           toastText : '',
           selectedMobileIndex : -1
          
        }
        this.closeDiscountBanner = this.closeDiscountBanner.bind(this);
        this.sessionError = this.sessionError.bind(this);
        this.isDesktop = getIsDesktop()
    }
        

    componentDidMount(){
        this.getUserInfo()
        this.instaGetPhotos()
    }

    closeDiscountBanner() {
        this.setState({isShowDiscountBanner : false})
    }
    getUserInfo = () => {
        var params = getRequestParams()
        params.q = getLogin()
        fetch(getRequestUrl() + "getUserInfo", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("getUserInfo = ", responseData)
            if (responseData.status == "OK" && responseData.user != undefined && responseData.user.all_data.data != undefined) {
                saveInstaCookie(responseData.user.username, responseData.user.pk, responseData.user.profile_pic_url)
                updateLoginData(responseData.user.username, responseData.user.profile_pic_url, responseData.user.pk)
                var isSession = (responseData.userOur.isSessionOk != undefined && !responseData.userOur.isSessionOk)
                this.setState({user : responseData.user.all_data.data, isPrivate : responseData.user.is_private, isShowSessionError : isSession, isShowDiscountBanner : !isSession})
                this.props.setAvatar(responseData.user.profile_pic_url)
                // const serialized = JSON.stringify(responseData.user);
                // localStorage.setItem('userInfo', serialized);
            } else {
                if (responseData.userOur != undefined && responseData.userOur.isSessionOk != undefined){
                    var isSession = (responseData.userOur.isSessionOk != undefined && !responseData.userOur.isSessionOk)
                    this.setState({isShowSessionError : isSession, isShowDiscountBanner : !isSession})
                }
                //window.location.href = '/auth'
            }
            // console.log("getUserInfo = " + JSON.stringify(responseData))
            
        })
    }
    instaGetPhotos = () => {
        var params = getRequestParams()
        fetch(getRequestUrl() + "instaGetPhotos", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.status == "OK") {
                const photos = responseData.photos
                var stories = []
                var reels = []
                var finalPhotos = []
                photos.forEach(photo => {
                    if (photo.id.includes("_&&&_storie")){
                        stories.push(photo)
                    } else if (photo.id.includes("_&&&_reels")){
                        reels.push(photo)
                    } else {
                        finalPhotos.push(photo)
                    }
                });
                const order_id = getUrlParameter("id")
                if (order_id){
                    this.togglePhotoItem(order_id, finalPhotos)
                }
                this.setState({photos : finalPhotos, reels : reels, stories : stories, allProgressCoins : responseData.our_info_all_coins, countProgressCoins : responseData.our_info_count_coins >= 0 ? responseData.our_info_count_coins : 0, isShowEmptyPhotos : (responseData.photos == undefined || responseData.photos.length == 0)})
                // const serialized = JSON.stringify(responseData);
                // localStorage.setItem('userPhotos', serialized);
                //setTimeout(this.renderPhotosTwice, 2000);
            } else {
                //window.location.href = '/auth'
            }
            // console.log("instaGetPhotos = " + JSON.stringify(responseData))
            this.setState({isLoading : false})
            
        })
    }
    updateInfo = () => {
        this.setState({isLoading : true})
        this.getUserInfo()
        this.instaGetPhotos()
    }
    orderCreated = (photo, index) => {
        var changedArray = this.state.photos
        changedArray[index] = photo
        this.setState({photos : changedArray})
        this.showToast(strings.ORDER_CREATED)
    }
    showToast = (text) => {
        this.setState({toastText : text})
        setTimeout(this.onToastFinish, 3000)
    }
    onToastFinish = () => {
        this.setState({toastText : ""})
    }
    updateFollowersProgress = (coins) => {
        this.setState({allProgressCoins : (this.state.allProgressCoins + coins), countProgressCoins : (this.state.countProgressCoins + coins)})
    }
    sessionError(){
        console.log("sessionError true")
        this.setState({isShowSessionBanner : true, isShowSessionError : true})
    }
    togglePhotoItem = (item_id, photos) => {
        const mPhotos = photos != undefined ? photos : this.state.photos

        var items = [...mPhotos]
        mPhotos.forEach((element, index) => {
            items[index].is_selected = item_id == undefined ? false : item_id == element.id
            if (items[index].is_selected){
                this.setState({selectedMobileIndex : index})
                return
            }
        })
        this.setState({photos : items})
    }
    render() {
        return (
            <div>
                {/* {this.state.isShowDiscountBanner && <DiscountBanner closeDiscountBanner={this.closeDiscountBanner}/>} */}
                
                <div className="promotion-page">
                    {/* {this.state.isShowSessionError && <SessionPromotionTooltip isShowSessionBanner={this.state.isShowSessionBanner} tooltipText={strings.DO_TASKS_DIALOG_4} acceptText={strings.PROMOTION_ACCEPT}/>} */}
                    <h2 className="h1 main__header-promotion">{strings.MENU_1}</h2>
                    {this.state.isPrivate && <div class="private-profile">{strings.PRIVATE_PROFILE}</div>}
                    <div >
                      <BuyFollowers sessionError={this.sessionError} updateFollowersProgress={this.updateFollowersProgress} allProgressCoins={this.state.allProgressCoins} countProgressCoins={this.state.countProgressCoins} updateMoney={this.props.updateMoney} count_coins={this.props.count_coins} user={this.state.user}/>                       
                      {/* <InstaProblemsTooltip /> */}

                        {/* <div onClick={this.updateInfo}
                                    className="btn btn_purple account-score__btn">Обновить</div> */}
                        
                            {/* {this.state.stories.length > 0 && 
                                <div className="stories_title">{strings.STORIES_TITLE}
                                <div className="account-content">{this.state.stories.map((item, index) => (
                                <PhotoItem togglePhotoItem={this.togglePhotoItem} photos={this.state.photos} sessionError={this.sessionError} count_money_r={this.props.count_money_r} orderCreated={this.orderCreated} updateMoney={this.props.updateMoney} count_coins={this.props.count_coins} photo={item} index={index} key={index}/>
                            ))}</div></div>}
                            {this.state.reels.length > 0 && 
                                <div className="stories_title">{strings.REELS_TITLE}
                                <div className="account-content">{this.state.reels.map((item, index) => (
                                <PhotoItem togglePhotoItem={this.togglePhotoItem} photos={this.state.photos} sessionError={this.sessionError} count_money_r={this.props.count_money_r} orderCreated={this.orderCreated} updateMoney={this.props.updateMoney} count_coins={this.props.count_coins} photo={item} index={index} key={index}/>
                            ))}</div></div>} */}
                            {/* {this.state.photos.length > 0 &&<div style={{marginBottom : '20px'}} className="stories_title">{strings.POSTS_TITLE}</div>} */}
                            <section className="account-content">
                                {this.state.photos.length > 0 && this.state.photos.map((item, index) => (
                                    <div className='mobile_width_order' index={index} key={index}>
                                        {this.isDesktop ? 
                                            <PhotoItem togglePhotoItem={this.togglePhotoItem} photos={this.state.photos} sessionError={this.sessionError} count_money_r={this.props.count_money_r} orderCreated={this.orderCreated} updateMoney={this.props.updateMoney} count_coins={this.props.count_coins} photo={item} index={index} key={index}/>
                                        :
                                            <Link to={"/promotion/order?id=" + this.state.photos[index].id}><PhotoItem togglePhotoItem={this.togglePhotoItem} photos={this.state.photos} sessionError={this.sessionError} count_money_r={this.props.count_money_r} orderCreated={this.orderCreated} updateMoney={this.props.updateMoney} count_coins={this.props.count_coins} photo={item} index={index} key={index}/></Link>
                                        }
                                    </div>
                                ))}
                            </section>
                        {this.state.isShowEmptyPhotos && <NoPhotos/>}
                    </div>
                    {this.state.isShowRequestError && 
                        <div>
                            <p ></p>
                            <div style={this.state.isShowFollowersTooltip ? {background: '#38343E'} : {}} onClick={this.toogleFollowersTooltip}
                                    className="btn_purple account-score__btn hover">Обновить</div>
                        </div>
                    }
                    {this.state.isLoading && <LoaderEarn/>}
                    
                </div>
                {this.state.toastText != "" && <Toast text={this.state.toastText}/>}
                <Route path={"/promotion/order"}>
                    <MobileOrder index={this.state.selectedMobileIndex} orderCreated={this.orderCreated} updateMoney={this.props.updateMoney} count_money_r={this.props.count_money_r} count_coins={this.props.count_coins} toogleTooltip={this.togglePhotoItem} photo={(this.state.selectedMobileIndex != -1 && this.state.selectedMobileIndex < this.state.photos.length) ? this.state.photos[this.state.selectedMobileIndex] : {}}/>
                </Route>
            </div>
          
        );
    }
}
export default Promotion;