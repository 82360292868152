import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import '../tooltips/tooltips.css'
import close from '../custom_icons/close.svg'
import pic_success from '../custom_icons/pic_success.svg'
import withdrawal_card from '../custom_icons/withdrawal_ya.svg'
import withdrawal_ya_pressed from '../custom_icons/withdrawal_ya_pressed.svg'
import withdrawal_qiwi from '../custom_icons/withdrawal_qiwi.svg'
import withdrawal_qiwi_pressed from '../custom_icons/withdrawal_qiwi_pressed.svg'
import BtnLoader from '../loaders/BtnLoader';
import { getEnabledBtnStyle } from '../../utils/PhotoItemHelper';
import { getRequestParams } from '../insta_custom_views/utils_android/CookieHelper';
import { getRequestUrl, getHttpParams } from '../../utils/Utils';
var strings = getLocalizedStrings()

class Withdrow extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            enterNumber : "",
            numberPlaceholder : strings.ENTER_YANDEX_2,
            cardNumberET : "",
            emailET : "",
            errorText : "",
            isLoading : false,

            isYMchecked : true,
            isQiwiChecked : false,
            isShowError : false,
            isShowSuccess : false
        }
        this.cardNumberChanged = this.cardNumberChanged.bind(this);
        this.closeTooltip = this.closeTooltip.bind(this);
        this.emailChanged = this.emailChanged.bind(this);
        this.sendRequestWithdrow = this.sendRequestWithdrow.bind(this);
        this.ymPicked = this.ymPicked.bind(this);
        this.qiwiPicked = this.qiwiPicked.bind(this);
        
        
    }
   
    closeTooltip(){
        this.props.toogleWithdrow()
    }
    sendRequestWithdrow(){
        this.setState({isShowError : false, errorText : ""})
        if (this.props.count_money_r < 100){
            this.setState({isShowError : true, errorText : strings.ERROR_WITHDROW_MONEY})
            return
          }
      
          if (this.state.emailET == undefined || this.state.emailET == ""){
            this.setState({isShowError : true, errorText : strings.ERROR_WITHDROW_UNDEFINED_EMAIL})
            return
          }
          if (this.state.cardNumberET == undefined || this.state.cardNumberET == "") {
            this.setState({isShowError : true, errorText : strings.ERROR_WITHDROW_UNDEFINED_CARD})
            return
          }
          this.setState({isShowError : true, isLoading : true})
          var params = getRequestParams();
          params.email = this.state.emailET
          params.cardNumber = this.state.cardNumberET
          params.type = (this.state.isYMchecked ? 2 : 3)

          fetch(getRequestUrl() + "sendMoneyRequest", getHttpParams(params)).then((response) => response.json())
          .then((responseData) => {
             if (responseData.response == -100) {
                //window.scrollTo(0, 0);
                this.props.showSessionError()
                this.setState({isShowError : true, errorText : strings.PROMOTION_ACCEPT_EARN_ACCEPT})
                
              } else if (responseData.response == -10){
                this.setState({isShowError : true, errorText : responseData.status})
              } else { 
                this.setState({isShowSuccess : true})
              }
              this.setState({isLoading : false})
          })
         
    }
    cardNumberChanged(event) {
        if (event){
            var text = event.target.value
            this.setState({cardNumberET : text})
            if (this.state.isShowError){
                this.setState({isShowError : false, errorText : ""})
            }
        }
    }
    emailChanged(event) {
        if (event){
            var text = event.target.value
            this.setState({emailET : text})
            if (this.state.isShowError){
                this.setState({isShowError : false, errorText : ""})
            }
        }
    }
    ymPicked(){
        this.setState({isYMchecked : true, isQiwiChecked : false, cardNumberET : ""})
    }
    qiwiPicked(){
        this.setState({isYMchecked : false, isQiwiChecked : true, cardNumberET : "+7"})
    }
    openRules = () => {
        window.open("https://www.webmoney.ru", '_blank')
    }
    render(){
      
            return (
                <div className="tooltip__container withdrow-container">
                    <div onClick={this.closeTooltip} className="tooltip_bg_withdrow"></div>
                    <i onClick={this.closeTooltip} className="tooltip__arrow rate-content__tooltip-arrow-withdrow reversed-arrow"></i>
                    {!this.state.isShowSuccess ? <div ng-style="chooseTypeStyle" className="withdrow-data-container">
                        <img style={{marginTop : "-24px"}} onClick={this.closeTooltip} className="close_withdrow_icon" src={close}/>
                        {/* <div className="contianer_width">
                            <label className="add-subscribe-form__label">{strings.CHOOSE_TYPE_WITHDRAW}
                                <div className="picker_container">
                                    <img style={this.state.isYMchecked ? {background : '#37353d'} : {}} onClick={this.ymPicked} className="ym_img hover" src={this.state.isYMchecked ? withdrawal_ya_pressed : withdrawal_card}/>
                                    <img style={this.state.isQiwiChecked ? {background : '#37353d'} : {}} onClick={this.qiwiPicked} className="qiwi_img hover" src={this.state.isQiwiChecked ? withdrawal_qiwi_pressed : withdrawal_qiwi}/>
                                </div>
                            </label>
                        </div> */}
                        {this.state.isYMchecked && 
                            <div style={{marginTop : "16px"}} className="flex">
                                <p className="bot_m_16">{strings.MIN_SUMM_PAYOUT} <span onClick={this.openRules} className="bot_m_16 nominal_y_money hover">{strings.MIN_SUMM_PAYOUT_3}</span></p>
                            </div>
                        }
                        <p className="bot_m_16">{strings.MIN_SUMM_PAYOUT_2}</p>
                        <p>{this.state.isYMchecked ? strings.ENTER_YANDEX : strings.ENTER_QIWI}</p>
                        <input onChange={this.cardNumberChanged} value={this.state.cardNumberET} className="form__input form__input_login" type="text" name="cardNumber"
                            placeholder={this.state.numberPlaceholder}/>
                        <p className="top_m_16">{strings.ENTER_EMAIL}</p>
                        <input onChange={this.emailChanged} value={this.state.emailET} className="form__input form__input_login" type="text" name="email"
                            placeholder={strings.YOUR_EMAIL}/>
                        <button style={this.state.isLoading ? getEnabledBtnStyle() : {}} onClick={this.sendRequestWithdrow} className="btn btn_purple form__btn send_req_btn hover">{strings.SEND_REQUEST}
                            {this.state.isLoading && <BtnLoader />}
                        </button>
                        <span ng-style="errorTextStyle" className="form__error">{this.state.errorText}</span>
                    </div> :
                    <div ng-style="requestSendStyle" className="withdrow-data-container center_text">
                        <img className="success_picture" src={pic_success}/>
                        <p className="success_text">{strings.WITHDROW_REQUEST_SEND}</p>
                        <p className="margin_top_24">{strings.WITHDROW_REQUEST_SEND_2}</p>
                        <button onClick={this.closeTooltip} className="btn btn_purple form__btn btn_withdrow_req_3">{strings.WITHDROW_REQUEST_SEND_3}</button>
                    </div>}
                </div>
            ); 
        
        
    }
}
export default Withdrow;
