import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import '../tooltips/tooltips.css'
import close from '../custom_icons/close.svg'
import promo_discount from '../custom_icons/promo_discount.svg'
var strings = getLocalizedStrings()

class DiscountBanner extends React.Component {
    constructor(props){
        super(props)

    }
    closeTooltip = () =>{
        this.props.closeDiscountBanner()
    }
    openBuyCoins = () =>{
        window.location.href = '/up-balance'
    }
    render(){
      
            return (
                <div ng-style="bannerStyle"  className="mobile-banner-sale-promotion discount_banner_container">
                    <img onClick={this.closeTooltip} className="close_discount hover" src={close} />
                    <img onClick={this.openBuyCoins} className="banner-img-mobile" src={promo_discount}/>
                    <div className="table-banner-text discount_white">
                    <p onClick={this.openBuyCoins} className="banner-text-mobile">{strings.DISCOUNT_BANNER}<br/>{strings.DISCOUNT_BANNER_2}</p>
                    </div>
            
                </div>
            ); 
        
        
    }
}
export default DiscountBanner;

