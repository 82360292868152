import React from 'react';
import task_link from '../custom_icons/task_link.svg'
import chevron_next_insta_android from '../custom_icons/chevron_next.svg'
import { getLocalizedStrings } from '../../utils/LocalizedStrings';

var strings = getLocalizedStrings()
class CustomTaskLink extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            currentDescription : ""
        }
     
        //this.toogleFirstTimeFollow = this.toogleFirstTimeFollow.bind(this);
    }

    doTask = () => {
        window.open("https://instarlike.com/work", '_blank')
    }
   
    skip = () => {
        this.props.loadNextTask()
    }
    
    render() {
        return (
            <div className="rate-content__item">
                    <div className="followers_container_appeal">
                        <img width="64" height="64" className="placeholder_task_custom"
                            src={task_link} alt=""/>
                            <p className="custom_task_link_title">{strings.SHARE_LINK_TASK}</p>
                            <p style={{marginBottom : '0px'}} className="custom_task_link_title custom_task_link_text">{strings.SHARE_LINK_TASK_2}</p>
                            <p className="custom_task_link_title custom_task_link_text">{strings.SHARE_LINK_TASK_3}</p>
                        
                        <img onClick={this.skip} src={chevron_next_insta_android} className="arrows__item arrows__item_next arrow_item_follower hover"></img>
                    </div>

                    
                    <div className="rate-content__actions">                        
                        <div onClick={this.doTask} className="btn-follow top_10">
                            {strings.FIND_MORE}
                        </div>                        
                        <div className="coins_rub_lashka rub_plashka">
                            <span className="subscribe_follow_reward right_4 subscribe_follow_reward_custom">{strings.SHARE_LINK_TASK_PERCENTS}</span>
                            <span className="subscribe_follow_reward left_4 subscribe_follow_reward_custom">+{strings.COINS_20}</span>
                        </div>
           
                    </div>
                    
                </div>
        );
    }
}
export default CustomTaskLink;
