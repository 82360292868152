/* eslint-disable eqeqeq */

import { useHistory } from "react-router-dom";
import React from 'react';

function MobileBack({toogleTooltip}) {
    const history = useHistory()
    const goBack = () => {
        history.goBack()
        toogleTooltip()
    }
    return (
        
       <div onClick={goBack} className="mobile-tooltip__back"></div>
    );
}

export default MobileBack;

