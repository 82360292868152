import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import '../tooltips/tooltips.css'

var strings = getLocalizedStrings()

class UnfollowTooltip extends React.Component {
    constructor(props){
        super(props)
    }
    
    render(){
      
            return (
                <div className="session_banner top_m align_left">
                    <i className="session_banner_arrow session_arrow_bottom"></i>
                    <div className="session_banner_line session_line_bottom"></div>
                    <div className="session_banner_container session_bottom_banner_container">
                        <p className="session_banner_title">{strings.SESSION_FOLLOW_TITLE}</p>
                        <p className="session_banner_text">{strings.SESSION_FOLLOW_TEXT}</p>
                        <p onClick={this.props.toogleFirstTimeFollow} className="session_banner_btn hover">{strings.QUESTION_OK}</p>
                    </div>
                </div>
            ); 
        
        
    }
}
export default UnfollowTooltip;
