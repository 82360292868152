import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import { calcStandartPrice, calcCoins } from './ShopHelper';
import CalcInput from './CalcInput';
import Arrow from './Arrow';
import BuyCoinsBtn from './BuyCoinsBtn';
import { declension } from '../../utils/Utils';
var strings = getLocalizedStrings()

class Calculator extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isLikes : true,
            isComments : true,
            isViews : true,
            isFollowers : true,
            likes : 100,
            comments : 100,
            views : 100,
            followers : 100,

            calculatedCoins : 3200,
            standartEnteredPrice : 640,
            saleEnteredPrice : 400,
            coinsDecl : strings.COIN_5,

            isShowCalculator : false
        }
        this.likesChanged = this.likesChanged.bind(this);
        this.commentsChanged = this.commentsChanged.bind(this);
        this.viewsChanged = this.viewsChanged.bind(this);
        this.followersChanged = this.followersChanged.bind(this);
        this.calcSum = this.calcSum.bind(this);
        this.arrowClicked = this.arrowClicked.bind(this);
    }
    likesChanged(isChecked, count){
        this.setState({isLikes : isChecked, likes : count})
        this.calcSum(isChecked, count, this.state.isComments, this.state.comments, this.state.isViews, this.state.views, this.state.isFollowers, this.state.followers)
    }
    commentsChanged(isChecked, count){
        this.setState({isComments : isChecked, comments : count})
        this.calcSum(this.state.isLikes, this.state.likes, isChecked, count, this.state.isViews, this.state.views, this.state.isFollowers, this.state.followers)
    }
    viewsChanged(isChecked, count){
        this.setState({isViews : isChecked, views : count})
        this.calcSum(this.state.isLikes, this.state.likes, this.state.isComments, this.state.comments, isChecked, count, this.state.isFollowers, this.state.followers)
    }
    followersChanged(isChecked, count){
        this.setState({isFollowers : isChecked, followers : count})
        this.calcSum(this.state.isLikes, this.state.likes, this.state.isComments, this.state.comments, this.state.isViews, this.state.views, isChecked, count)
    }
    calcSum(isLikes, likes, isComments, comments, isViews, views, isFollowers, followers){
        var sum = 0
        if (isLikes){
            sum += likes*2
        }
        if (isComments){
            sum += comments*10
        }
        if (isViews){
            sum += views*10
        }
        if (isFollowers){
            sum += followers*10
        }
        this.setState({calculatedCoins : sum, standartEnteredPrice : calcStandartPrice(sum), saleEnteredPrice : calcCoins(sum), coinsDecl : declension(sum, strings.COIN, strings.COIN_2, strings.COIN_5)})
    }
    arrowClicked(isChecked){
        this.setState({isShowCalculator : isChecked})
    }
    render(){
        return (
            <div>
                <Arrow arrowClicked={this.arrowClicked} title={strings.USE_CALC}/>
                {this.state.isShowCalculator &&  <div ng-style="useCalcStyle" className="buy-another-container transformable-profiles">
                    <CalcInput stateChanged={this.likesChanged} text={strings.LIKES}/>
                    <CalcInput stateChanged={this.commentsChanged} text={strings.COMMENTS_FULL}/>
                    <CalcInput stateChanged={this.followersChanged} text={strings.FOllOWERS_CALC}/>
                    <CalcInput stateChanged={this.viewsChanged} text={strings.VIEWS}/>
                    <div className="line-calc-line"></div>
                    <div className="buy-descr-coins m_dif">
                        <p>{strings.END_CALC}</p>
                        <strong>{this.state.calculatedCoins} {this.state.coinsDecl}</strong>
                    </div>
                <BuyCoinsBtn buyCoins={this.props.buyCoins} coinsCount={this.state.calculatedCoins} coins={strings.BUY} fullPrice={this.state.standartEnteredPrice} realPrice={this.state.saleEnteredPrice}/>
                </div>}
            </div>
        ); 
        
        
    }
}
export default Calculator;
