import React from 'react';
import { addLogin, saveInstaCookie, getLogin } from '../custom_views/insta_custom_views/utils_android/CookieHelper';
import { getLocalizedStrings } from '../utils/LocalizedStrings';

import { getProgress, videoIconStyle, isStartCampaign, isFinishedCampaign, isInprocessCampaign, commentsOffStyle, getProgressColorLike, getProgressColorComment, videoViewsStyle, getProgressColorView, getSource, getIsDesktop } from '../utils/PhotoItemHelper';
import NotEnoughtCoins from '../custom_views/tooltips/NotEnoughtCoins';
import LanscapeOrder from '../custom_views/promotion/LandscapeOrder';
import MobileOrder from '../custom_views/promotion/MobileOrder';
import error_img from '../custom_views/custom_icons/error_img.svg'
var strings = getLocalizedStrings()
var canvas;
var img;
class PhotoItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            notEnoughtCoins: "",
            // isShowTooltip : false,
            isShowNotEnought: false,
            isMobile: (window.screen.width <= 768 ? true : false),
            src: ""
        }

    }
 
    render() {
        const photos = this.props.photos
        const isShowSelected = this.props.photo.is_selected
        const isMobileAnd3rd = (this.props.index + 1) % 3 == 0
        return (
            <div style={(!this.state.isMobile && isShowSelected) ? { height: '800px', paddingRight : isMobileAnd3rd ? 0 : '' } : { paddingRight: isMobileAnd3rd ? '0' : '' }} className="account-content__item">
                <div className="mobile_click hover_box" onClick={() => this.props.togglePhotoItem(this.props.photo.id)}>
                    <i style={videoIconStyle(this.props.photo)} className="account-content__video-icon"></i>

                    <img className="account-content__photo" src={getSource(this.props.photo)} />
             
                    <div className="account-content__social-actions">
                        <span className="account-content__like">{this.props.photo.like_count}</span>
                        <span className="account-content__comment">{this.props.photo.comment_count}</span>
                        <span className="account-content__warn">0</span>
                    </div>
                    <div style={isStartCampaign(this.props.photo)} className="account-content__progress account-content__progress_promo">{strings.PROMOTE_TASK}</div>
                    <div style={isFinishedCampaign(this.props.photo)} className="account-content__progress account-content__progress_done">{strings.COMPLETED_TASK}</div>
                    <div style={isInprocessCampaign(this.props.photo)} className="account-content__progress">{strings.IN_PROGRESS_TASK}</div>
                </div>

                {(isShowSelected && !this.state.isMobile) && <LanscapeOrder index={this.props.index} orderCreated={this.props.orderCreated} updateMoney={this.props.updateMoney} count_coins={this.props.count_coins} toogleTooltip={this.props.togglePhotoItem} photo={this.props.photo} />}
                {/* {(isShowSelected && this.state.isMobile) && <MobileOrder index={this.props.index} orderCreated={this.props.orderCreated} updateMoney={this.props.updateMoney} count_money_r={this.props.count_money_r} count_coins={this.props.count_coins} toogleTooltip={this.props.togglePhotoItem} photo={this.props.photo} />} */}
            </div>
        );


    }
}
export default PhotoItem;
