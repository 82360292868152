import React from 'react';
import qs from 'querystring'
import {getRequestParams} from './utils_android/CookieHelper'
import { showAndroidToast } from './utils_android/AndroidMethods';
import { getRequestUrl, getHttpParams } from '../../utils/Utils';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
var strings = getLocalizedStrings()
class BaseEarnTask extends React.Component {
    constructor(props){
        super(props)
        this.LIKE_PHOTO_TASK_DESC = strings.LIKE_PHOTO_TASK_DESC
        this.FOLLOW_PHOTO_TASK_DESC = strings.FOLLOW_PHOTO_TASK_DESC
        this.COMMENT_PHOTO_TASK_DESC = strings.COMMENT_PHOTO_TASK_DESC
        this.VIDEO_TASK_DESCRIPTION = strings.VIDEO_TASK_DESCRIPTION
        this.state = {
            extraOrdersCount : this.props.extraOrdersCount,
            isShowError : false
        }
        this.redirectType = -1
        this.launchTime = 0
    }
    componentDidMount(prevProps){
        if (prevProps != this.props){
            this.setState({extraOrdersCount : this.props.extraOrdersCount})
        }
        setInterval(this.checkPageFocus, 1000);
    }
    onResume(){
        console.log("onResume base")
    }
    checkPageFocus = () => {
        if (window.document.hasFocus() && this.redirectType != -1 ) {
       
          this.onResume()
          console.log("this.redirectType = " + this.redirectType )
          this.redirectType = -1;
          console.log("this.redirectType = " + this.redirectType )          
        }  
     }
     
     
    instaLike = (grade, recaptchaToken) => {
        this.setState({isShowError : false})
        var params = getRequestParams();
        params.timestamp = (recaptchaToken && recaptchaToken != "") ? recaptchaToken : this.props.timestamp
        params.photo_id = this.props.currentPhotoTask.photo.photo_id;
        params.grade = grade;
        params._id = this.props.currentPhotoTask.photo._id;
        console.log("instaLike params" + JSON.stringify(params))
        fetch(getRequestUrl() + "instaLike", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                this.props.loadNextOrder(true)
                if (grade == 1){
                    this.props.taskCompleted()
                }
            } else {
                this.setState({isShowError : true})
                this.errorTask()
            }
            console.log("instaLike" + JSON.stringify(responseData))
        })
    }
    skipOrder = (isSkipedAuto, id) =>{
        this.props.showLoader()
        if (!this.props.isCurrentExtra && this.props.currentPhotoTask.photo.photo_id) {
            this.instaLike(0);
        } else {
            if (isSkipedAuto == undefined) {
                console.log("isSkipedAuto true")
                
                
            } else {
                var params2 = getRequestParams();
                fetch(getRequestUrl() + "clearExtraWithNulls", getHttpParams(params2)).then((response) => response.json())
                .then((responseData) => {
                   
                })
            }

            var params = getRequestParams();
            params._id = id
            console.log("params skipOrder = " + JSON.stringify(params));

            fetch(getRequestUrl() + "skipExtraOrder", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.response == 1 || responseData.response == 2) {
                        
                    }
                    console.log("skipOrder = " + JSON.stringify(responseData));
                    var random =  Math.random()
                    
                    this.props.loadNextOrder((random >= 0.5))
                    //this.props.loadNextOrder(this.props.onOrderSkipped() >= 3)
                })
        }
    }
    
    appealClicked = () =>{
        
        //showAndroidToast("Жалоба отправлена")
    }
    errorTask(){
        this.props.hideLoader()
        //showAndroidToast((strings.ERROR_LIKE_1 + " " + strings.ERROR_LIKE_2))
    }
    openUrl(url, taskType) {
        this.launchTime = new Date().getTime() / 1000
        //this.redirectType = taskType
        setTimeout(() => this.redirectType = taskType, 1000);
        window.open(url)
    }
    getSource = (photo) => {
        if (photo == undefined) {
            return "";
        }
        switch (photo.media_type) {
            case 8:
                return photo.carousel_media[0].image_versions2.candidates[0].url;
            case 1:
                return photo.image_versions2.candidates[0].url;
            case 2:
                return photo.image_versions2.candidates[0].url;
            default:
                return "";
        }
    } 
}
export default BaseEarnTask;