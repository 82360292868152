import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import '../tooltips/tooltips.css'
import close from '../custom_icons/close.svg'
var strings = getLocalizedStrings()

class EarnQuestionTooltip extends React.Component {
    constructor(props){
        super(props)

    }
    closeTooltip = () =>{
        this.props.toogleEarnQuestion()
    }
    render(){
      
            return (
                    <section className="question_descr auth_tooltip">
                        <img onClick={this.closeTooltip} className="close_auth_tooltip hover" src={close}/>
                        <h2 className="h1 main__header">{strings.QUESTION_TITLE}</h2><br/>
                        <p>Выполняя задания на этой странице, ты получаешь:
                            <br/><br/>
                            – монеты, которые можешь обменять на лайки, комментарии, новых подписчиков или просмотры видео;
                            <br/><br/>
                            – реальные деньги.
                            <br/><br/>
                            </p>
                            <h2 className="h2 uppercased_bold">Как снять деньги</h2>
                            <br/>
                            <p>
                            1. Перейди в раздел «Заработать с нами».
                            <br/><br/>
                            2. Найди графу «Ты заработал» и нажми на кнопку «Снять».
                            <br/><br/>
                            3. Выбери удобный способ получения денег.
                            <br/><br/>
                            </p>
                            <h2 className="h2 uppercased_bold">Выполнение заданий</h2>
                            <br/>
                            <p>
                            Для выполнения задания ты будешь перенаправлен в Instagram. Там подписываешься на профиль/пишешь
                            комментарий/ставишь лайк/смотришь видео > возвращаешься в данное приложение и получаешь монеты.
                            <br/><br/>
                            Данный сервис не производит никаких автоматических действий, люди выполняют все задания вручную. Благодаря
                            этому Инстаграм никак не сможет вычислить накрутку. Так мы заботимся о том, чтобы наши пользователи не
                            попадали в теневые баны.</p>
                            <br/>
                            <p onClick={this.closeTooltip} className="btn-get-money">{strings.QUESTION_OK}</p>
                  </section>
            ); 
        
        
    }
}
export default EarnQuestionTooltip;
