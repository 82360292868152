/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import { getUrlParameter } from '../../utils/UrlHelperAndroid';
var strings = getLocalizedStrings()

class PaymentResult extends React.Component {
    constructor(props){
        super(props)
    }
    onButtonClick = () => {
        const isSuccess = getUrlParameter('success') == "true"
        if (isSuccess){
            window.location.href = "/promotion"
        } else {
            window.location.href = "/up-balance"
        }
    }
    render(){
        const isInstarlike = getUrlParameter('is_app') == "false"
        const isSuccess = getUrlParameter('success') == "true"
        return (
            <div className="payment_result_div">
                <img className="center_hor_result" style={{width : (this.props.title == strings.PAYMENT_RESULT_FAIL_TITLE ? '114px' : '142px'), height : '114px', marginTop : '100px'}} src={this.props.image}/>
                <p className="payment_result_title">{this.props.title}</p>
                <p className="payment_result_text">{this.props.text} {(!isInstarlike && isSuccess) ? strings.PAYMENT_RESULT_BACK : ''}</p>
                {this.props.isPaymentApp == undefined && <div onClick={this.onButtonClick} className="result_payment_btn hover">{isSuccess ? strings.PAYMENT_RESULT_SUCCESS_BTN : strings.PAYMENT_RESULT_FAIL_BTN}</div>}
                {/* {true && <div onClick={this.onButtonClick} className="result_payment_btn hover">{isSuccess ? strings.PAYMENT_RESULT_SUCCESS_BTN : strings.PAYMENT_RESULT_FAIL_BTN}</div>} */}
                {isSuccess && 
                <div style={{marginTop : '24px'}}>
                    <b>Перед заказом подписчиков, отключите функцию «Пометить для проверки»</b><br/><br/>
                    Инструкция:<br/>
                    1. Войдите в настройки<br/>
                    2. Пролистайте до блока «Взаимодействие с вами»<br/>
                    3. Пригласить друзей и подписаться<br/>
                    4. Пометить для проверки (убрать галочку).<br/>
                </div>}
            </div>

        ); 
        
        
    }
}
export default PaymentResult;
