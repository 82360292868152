import React from 'react';
import qs from 'querystring'
import { getRequestParams } from '../pages_android/custom_views/insta_custom_views/utils_android/CookieHelper';
import { getUrlParameter } from '../pages_android/custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
import  arrow  from './icons_android/arrow_left_android.svg';
import  burger  from './icons_android/mobile_bar_android.svg';
import  logo  from '../pages_android/custom_views/custom_icons/logo.svg';
import  rubl_icon  from '../pages_android/custom_views/custom_icons/rubl_icon.svg';
import  cart  from '../pages_android/custom_views/custom_icons/cart.svg';
import  coins  from '../pages_android/custom_views/custom_icons/coins.svg';
import  help_hover  from '../pages_android/custom_views/custom_icons/help_hover.png';
import { finish } from '../pages_android/custom_views/insta_custom_views/utils_android/AndroidMethods';
import { declension } from '../pages_android/utils/Utils';
import { getLocalizedStrings } from '../pages_android/utils/LocalizedStrings';
var strings = getLocalizedStrings()
class NavbarAndroid extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            countMoney : 0.0,
            isBack : false
        }
        this.buyCoinsClicked = this.buyCoinsClicked.bind(this);
        this.workClicked = this.workClicked.bind(this);
    }
    sideButtonClicked = function(){
        var backUrl = getUrlParameter("back")
        if (this.state.isBack){
            window.location.href = '/' + backUrl + "?testReactJS=1"
        } else {
            var overlay = document.createElement('button');

            overlay.setAttribute('title', 'Закрыть меню');
            overlay.className = 'overlay';
            document.querySelector('.app').appendChild(overlay);
            document.querySelector('.aside').style.left = '0';
            overlay.addEventListener('click', function (e) {

                document.querySelector('.aside').removeAttribute('style');
                document.querySelector('.app').removeChild(overlay);
        
            });
        }
        
    }
    componentDidMount(){
        var backUrl = getUrlParameter("back")
        if (backUrl != undefined && backUrl != ""){
            this.setState({isBack : true})
        }
    }
    buyCoinsClicked(){
        window.location.href = '/up-balance'
    }
    workClicked(){
        window.location.href = '/work'
    }
    render() {
        return (
           <header className="header justify_content">
                {this.props.isShowMenu ? <div className="header_line">
                    <img onClick={() => this.sideButtonClicked()} src={this.state.isBack ? arrow : burger} className={this.state.isBack ? 'header__mobile-menu_back' : 'header__mobile-menu_burger'}/>
                    <div className="header__coins">
                        <img className="header_logo_instarlike" src={logo}/>
                        {/* <div className="flex hover">
                            <img onClick={this.workClicked} className="coins__icon coins__icon_rubles" src={rubl_icon}/>
                            <span onClick={this.workClicked} className="coins__value coins__value_rubles">{parseFloat(this.props.count_money_r).toFixed(2)}</span>
                        </div>                         */}
                        <img onClick={this.buyCoinsClicked} className="coins__icon left_16" src={coins}/>
                        <div onClick={this.buyCoinsClicked} className="flex hover">
                            <span className="coins__value">{this.props.count_coins}<br/>{declension(this.props.count_coins, strings.COIN, strings.COIN_2, strings.COIN_5)}</span>
                            
                            <img className="coins_up_image" src={cart}/>

                        </div>
                        
                        
                    </div>
                    {/* <div className={this.props.isUpmob ? "block" : "not_display"}>
                        <p className="user_name money_nav_text header_title_16">{parseFloat(this.props.count_money_r).toFixed(2)}₽</p>
                    </div> */}
                    
                </div> : 
                <div className='flex relative'>
                    <img className="logo_height" src={logo}/>
                </div>}
                {/* {!this.props.isShowMenu && <div onClick={this.questionClicked} className="circle__icon_help hover">
                        <img src={help_hover} className="img_help"/>
                    </div>} */}
  
            </header>
        );
    }
}
export default NavbarAndroid;