import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
var strings = getLocalizedStrings()

class CalcInput extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isChecked : true,
            count : 100
        }
        this.toogle = this.toogle.bind(this);
        this.countChanged = this.countChanged.bind(this);
    }
    toogle(){
        var isChecked = !this.state.isChecked
        this.setState({isChecked : isChecked})
        this.props.stateChanged(isChecked, this.state.count)
    }
    countChanged(event){
        if (event){
            var value = event.target.value
            this.setState({count : value})
            this.props.stateChanged(this.state.isChecked, value)
        }
    }
    render(){
        return (
            <div className="line-calc-order">
                <div onClick={this.toogle} className="text-container-calc">
                    <div className={this.state.isChecked ? "calc-likes-checkbox calc-checked hover" : "calc-likes-checkbox hover"}></div>
                    <p className="text-calc">{this.props.text}</p>
                </div>
                <input ng-style="likesETStyle" onChange={this.countChanged} type="number" min="1"
                className="input add-subscribe-form__input input-another-container width_80" name="Count" value={this.state.count}/>
            </div>

        ); 
        
        
    }
}
export default CalcInput;
