/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import { PaymentType } from '../../utils/Utils';
import picked_bank_cards from '../custom_icons/picked_bank_cards.svg'
import bank_cards from '../custom_icons/bank_cards.svg'
import icon_usdt_color from '../custom_icons/icon_usdt_color.svg'
import icon_usdt_bw from '../custom_icons/icon_usdt_bw.svg'
import pay_checked from '../custom_icons/pay_checked.svg'
import ino_payment from '../custom_icons/ino_payment.svg'
import ino_payment_unselected from '../custom_icons/ino_payment_unselected.svg'
import { getInstaLogin } from '../insta_custom_views/utils_android/CookieHelper';
var strings = getLocalizedStrings()

const PaymentTypePicker = ({ paymentType, setPaymentType, price }) => {
    const isCardPayment = paymentType == PaymentType.card
    const isInoCardPayment = paymentType == PaymentType.ino_card
    const isUSDTPayment = paymentType == PaymentType.usdt
    return (
        <div className="pay_types_container">
            <div className='payment_type_title'>{strings.PAYMENT_TYPE_TITLE}</div>
            <div onClick={() => setPaymentType(PaymentType.card)} className="relative hover">
                <img alt="" style={isCardPayment ? {opacity: "1"} : {}} className="pay_checked" src={pay_checked}/>
                <div style={isCardPayment ? {border: "1px solid black"} : {}} className="type_pay_container">
                    <img alt="" className="type_pay_img" src={isCardPayment ? picked_bank_cards : bank_cards}/>
                    <p className="type_pay_title">{strings.BANK_CARDS_RU}</p>
                </div>
            </div>
            {getInstaLogin() == 'zhenekvv' && <div onClick={() => setPaymentType(PaymentType.ino_card)} className="relative hover">
                <img alt="" style={isInoCardPayment ? {opacity: "1"} : {}} className="pay_checked" src={pay_checked}/>
                <div style={isInoCardPayment ? {border: "1px solid black"} : {}} className="type_pay_container">
                    <img alt="" className="type_pay_img" src={isInoCardPayment ? ino_payment : ino_payment_unselected}/>
                    <p className="type_pay_title">{strings.BANK_CARDS_WORLD}</p>
                </div>
            </div>}     
            {price >= 299 && <div onClick={() => setPaymentType(PaymentType.usdt)} className="relative hover">
                <img alt="" style={isUSDTPayment ? {opacity: "1"} : {}} className="pay_checked" src={pay_checked}/>
                <div style={isUSDTPayment ? {border: "1px solid black"} : {}} className="type_pay_container">
                    <img alt="" className="type_pay_img" src={isUSDTPayment ? icon_usdt_color : icon_usdt_bw}/>
                    <p className="type_pay_title">USDT</p>
                </div>
            </div>}
        </div>
            
    )
}

export default PaymentTypePicker
