import React from 'react';
import { getLocalizedStrings } from './utils/LocalizedStrings';
import '../pages_android/custom_views/shop_views/shop.css'
import { declension, getIsTagsEuro } from './utils/Utils';
import stars_mob from '../pages_android/custom_views/custom_icons/stars_mob.svg'
import error_payment from '../pages_android/custom_views/custom_icons/error_payment.png'
import success_payment from '../pages_android/custom_views/custom_icons/success_payment.png'
import BuyCoinsBtn from './custom_views/shop_views/BuyCoinsBtn';
import { calcStandartPrice, calcCoins } from './custom_views/shop_views/ShopHelper';
import payment_methods from './custom_views/custom_icons/payment_methods.png'
import payment_methods_desktop from './custom_views/custom_icons/payment_methods_desktop.svg'
import payment_methods_mobile from './custom_views/custom_icons/payment_methods_mobile.svg'
import ConfirmAppPayment from './custom_views/shop_views/ConfirmAppPayment';
import { getUrlParameter } from './utils/UrlHelperAndroid';
import PaymentResult from './custom_views/shop_views/PaymentResult';
import NewDesignShopButtons from './custom_views/shop_views/NewDesignShopButtons';
var strings = getLocalizedStrings()
class PaymentApp extends React.Component {
    constructor(props){
        super(props)
        this.state = {
           isShowConfirm : false,
           isShowAnotherAmount : true,
           enteredCoinsDecl : strings.COIN_5,
           coinsET : 100,
           standartEnteredPrice : calcStandartPrice(100),
           saleEnteredPrice : calcCoins(100),

           calculatedCoins : 0,
           lang : 'ru',
           confirmPrice : 0,
           confirmCoins : 0,
           fakePrice : 0
        }
        this.coinsETChanged = this.coinsETChanged.bind(this);
        this.buyAnotherAmountClicked = this.buyAnotherAmountClicked.bind(this);
        this.toogleConfirm = this.toogleConfirm.bind(this);
    }
    
    coinsETChanged(event){
        if(event){
            var value = event.target.value
            this.setState({coinsET : value, standartEnteredPrice : calcStandartPrice(value), saleEnteredPrice : calcCoins(value), enteredCoinsDecl : declension(value, strings.COIN, strings.COIN_2, strings.COIN_5)})
        }
    }

    buyAnotherAmountClicked(isChecked){
        this.setState({isShowAnotherAmount : isChecked})
    }
    toogleConfirm(){
        this.setState({isShowConfirm : !this.state.isShowConfirm})
    }
    buyCoins = (coins, price, fakePrice) => {
        this.setState({isShowConfirm : true, confirmPrice : price, confirmCoins : coins, fakePrice : fakePrice})
       
    }
    componentDidMount(){
        const tiktok_coins = getUrlParameter('tiktok_coins')
        if (tiktok_coins != undefined && tiktok_coins != null && tiktok_coins != 0){
            this.setState({isShowConfirm : true, confirmPrice : calcCoins(tiktok_coins), confirmCoins : tiktok_coins, fakePrice : calcStandartPrice(tiktok_coins)})
        }
        const product_id = getUrlParameter("product_id")
        console.log("product_id = " + product_id)
        if (product_id != undefined && product_id != null && product_id != ""){
            const coins = this.getCoinsByProductID(product_id)
            
            console.log("coins = " + coins)
            this.setState({isShowConfirm : true, confirmPrice : calcCoins(coins), confirmCoins : coins, fakePrice : calcStandartPrice(coins)})
        }
    }
    getCoinsByProductID = (product_id) => {
        switch (product_id) {
                case "coins_100":
                return 100
                case "coins_500":
                return 500  
                case "coins_1000":
                return 1000 
                case "coins_5000":
                return 5000
                case "coins_10000":
                return 10000 
                case "coins_50000":
                return 50000    
                case "coins_100000":
                return 100000 
                case "com.secuqdev.HashtagsNew.100":
                return 100
                case "com.secuqdev.HashtagsNew.500":
                return 500
                case "com.secuqdev.HashtagsNew.1000":
                return 1000
                case "com.secuqdev.HashtagsNew.5000":
                return 5000
                case "com.secuqdev.HashtagsNew.10000":
                return 10000
                case "com.secuqdev.HashtagsNew.50000":
                return 50000
           
            default:
                return 100
        }
    }
    render(){
        const isTagsEuro = getIsTagsEuro()
        const isShowSuccessPaid = (getUrlParameter('success') != undefined && getUrlParameter('success') != null && getUrlParameter('success') != "")
        // const isShowSuccessPaid = true
        if (isShowSuccessPaid){
            return (
                <div className="promotion-page">
                    {getUrlParameter('success') == "true" ? <PaymentResult isPaymentApp title={strings.PAYMENT_RESULT_SUCCESS_TITLE} text={strings.PAYMENT_RESULT_SUCCESS_TEXT} image={success_payment}/> : <PaymentResult title={strings.PAYMENT_RESULT_FAIL_TITLE} text={strings.PAYMENT_RESULT_FAIL_TEXT} image={error_payment}/>}
                </div>)
        } else {
            return (
                <div className="promotion-page">
                    {this.state.isShowConfirm ? <ConfirmAppPayment fakePrice={this.state.fakePrice} lang={this.state.lang} price={this.state.confirmPrice} coins={this.state.confirmCoins} toogleConfirm={this.toogleConfirm}/> :
                    <div>
                        <h2 className="h1 main__header_profile">{strings.BUY_COINS_TITLE}</h2>
                        <div className="new-up-balance">
                            <div className="buy-buttons-container">
                                <div className="buy-grid-container">
                                    <img className="full_width_m" src={stars_mob} />
                                    <div className="buy-title-container">{strings.BUY_MORE_CALC} <br/>{strings.PAY_LESS_CALC}</div>
                                </div>
                                <NewDesignShopButtons buyCoins={this.buyCoins}/>
                            </div>

                            <div>
                            <div className="get_followers_settings">
                                <p style={{color : 'black', textTransform : 'none'}} className="font_16">{strings.BUY_ANOTHER_CALC}</p>
                            </div>
                                {this.state.isShowAnotherAmount && <div className="buy-another-container transformable-profiles">
                                    <input value={this.state.coinsET} onChange={this.coinsETChanged} type="number" min="1" className="input add-subscribe-form__input input-another-container" name="Count"/>
                                    <p className="buy-another-right-text">{this.state.enteredCoinsDecl}</p>
                                    <BuyCoinsBtn buyCoins={this.buyCoins} coinsCount={this.state.coinsET} coins={strings.BUY} fullPrice={this.state.standartEnteredPrice} realPrice={this.state.saleEnteredPrice}/>
                                </div>}
                            </div>
                            
                            <div className="line_payment"/>
                            <img className="desctop_payments" src={payment_methods}/>
                            <img className="mobile_payments" src={payment_methods}/>
                        </div>
                    </div>}
                    
                </div>
            ); 
        }
        
    }
}
export default PaymentApp;
