import React from 'react';
import { addLogin, saveInstaCookie, getLogin } from '../custom_views/insta_custom_views/utils_android/CookieHelper';
import empty_user_insta from '../custom_views/insta_custom_views/insta_icons_tasks/empty_user_insta.svg'
class MenuLogin extends React.Component {
    constructor(props){
        super(props)
        // var params = this.props.item.split(",")
        // this.login = params[0]
        // this.avatar = params[1]
        // if (params.length > 2){
        //     this.user_id = params[2]
        // }

    }
    openProfile = () => {
        saveInstaCookie(this.props.item.login, this.props.item.user_id, this.props.item.avatar)
        window.location.href = '/promotion'
    }
    render(){
      
            return (
                <div style={this.props.item.login == getLogin() ? {display : "none"} : {}} onClick={this.openProfile} className="flex nav__item">
                    {/* <img src={this.props.item.avatar} className="nav__profile-photo"/> */}
                    <div className="tooltip tooltip_style">
                        <span  className="tooltip_span">{this.props.item.login}</span>
                    </div>
                </div>
            ); 
        
        
    }
}
export default MenuLogin;
