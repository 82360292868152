
  import React from 'react';
  import { getLocalizedLandingStrings } from './LocalizedLanding';
  import ReviewPlashka from '../landing/ReviewPlashka';
  import arrow_circle_right from '../landing/icons/arrow_circle_right.svg'
  import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
  import 'pure-react-carousel/dist/react-carousel.es.css';
  import '../landing/css/slider.css';
    var strings = getLocalizedLandingStrings()
    
    class Corousel extends React.Component {
        constructor(props){
            super(props)
            this.isMobile = (window.screen.width <= 768)
            this.state = {
                isDot1 : true,
            }
        }
        componentDidMount(){
            var delay = 5000
            if (this.isMobile){
                delay = 9000
            }
            setInterval(this.changeDots, delay);
        }
        changeDots = () => {
            this.setState({isDot1 : !this.state.isDot1})
        }
        render(){
                return (
                    <div className="reviews_landscape relative">
                        <ul className="pagination-container">
                            <img data="0" id="pagination-img-1" className={this.state.isDot1 ? "pagination-img active" : "pagination-img"} />
                            <img data="1" id="pagination-img-2" className={!this.state.isDot1 ? "pagination-img active" : "pagination-img"}/>
                            {/* <img data="2" id="pagination-img-3" className="pagination-img"/> */}
                        </ul>
                        <CarouselProvider playDirection={'forward'} interval={this.isMobile ? 3000 : 5000} isPlaying={"true"} step={this.isMobile ? 1 : 3} infinite={"true"} visibleSlides={this.isMobile ? 1 : 3} totalSlides={6}>
                            <Slider>
                                <ReviewPlashka name={strings.REVIEW_NAME_1} text={strings.REVIEW_TEXT_1}/>
                                <ReviewPlashka name={strings.REVIEW_NAME_2} text={strings.REVIEW_TEXT_2}/>
                                <ReviewPlashka name={strings.REVIEW_NAME_3} text={strings.REVIEW_TEXT_3}/>
                                <ReviewPlashka name={strings.REVIEW_NAME_4} text={strings.REVIEW_TEXT_4}/>
                                <ReviewPlashka name={strings.REVIEW_NAME_5} text={strings.REVIEW_TEXT_5}/>
                                <ReviewPlashka name={strings.REVIEW_NAME_6} text={strings.REVIEW_TEXT_6}/>
                            </Slider>
                        </CarouselProvider>
                    </div>
                  
                ); 
            
            
        }
    }
    export default Corousel;
    