import React from 'react';
import { getLocalizedLandingStrings } from './LocalizedLanding';
  
    var strings = getLocalizedLandingStrings()
    
    class RoundedPlashka extends React.Component {
        constructor(props){
            super(props)
        }
        
        render(){
                return (
                    <div className="rounded-plashka">
                        <img className="img-circle-plashka" src={this.props.image} />
                        <h2 className="rounded-plashka-top-text">{this.props.text}</h2>
                        <p className="rounded-plashka-text">{this.props.description}</p>
                    </div>
                ); 
            
            
        }
    }
    export default RoundedPlashka;
    
