/* eslint-disable eqeqeq */
import React from 'react';
import { openUrl, copyId } from '../pages_android/custom_views/insta_custom_views/utils_android/AndroidMethods';
import '../components_android/menu.css'
import logo from '../pages_android/custom_views/custom_icons/logo.svg'
import gift_banner from '../pages_android/custom_views/custom_icons/gift_banner.svg'
import add from '../pages_android/custom_views/custom_icons/add.svg'
import up_white from '../pages_android/custom_views/custom_icons/up_white.svg'
import icon_universal from '../pages_android/custom_views/custom_icons/icon_universal.svg'
import icon_stories from '../pages_android/custom_views/custom_icons/icon_stories.svg'
import { getLocalizedStrings } from '../pages_android/utils/LocalizedStrings';
import { getCookie, getInstaAvatar, getInstaLogin, getInstaLogins } from '../pages_android/custom_views/insta_custom_views/utils_android/CookieHelper';
import MenuLogin from '../pages_android/list_items/MenuLogin';
var strings = getLocalizedStrings()
var activeStyle = {backgroundColor: '#53576f',borderLeft: '6px solid var(--main)',paddingLeft: '18px',opacity: '1'}
class MenuAndroid extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            avatar : getInstaAvatar(),
            username : getInstaLogin(),
            isShowProfiles : false
        }
        this.accounts = getInstaLogins()
        // if (logins != undefined && logins != null && logins != ""){
        //     if (logins.includes("&!&")){
        //         this.accounts = logins.split("&!&").reverse()
        //     } else {
        //         this.accounts = [logins].reverse()
        //     }
        // }

        console.log("window.location.pathname == " + window.location.pathname)
        this.toogleProfiles = this.toogleProfiles.bind(this);
        this.addAccountClicked = this.addAccountClicked.bind(this);
    }
    toogleProfiles() {
        this.setState({isShowProfiles : !this.state.isShowProfiles})
    }
    addAccountClicked() {
        window.location.href = "/auth?isAddAccount=true"
    }
   
    render() {
        return (
            <div className="aside__wrapper">
                <div className="aside_overflow">
                    <div>
                        <nav className="nav_border">
                            <ul className="nav">
                            <li className="aside_header_mobile">
                                <img className="logo_style" src={logo} />
                            </li>
                            <li><div onClick={this.toogleProfiles} className="nav__item nav__item_profile">
                                <div className="flex">
                                    {this.props.avatar != "" && <img src={this.props.avatar} className="nav__profile-photo"/>}
                                    <div className="tooltip tooltip_style"><span ng-click="openProfile()" className="tooltip_span">{this.state.username}</span>
                                        <img style={this.state.isShowProfiles ? {transform : 'rotate(360deg)'} : {}} src={up_white}  className="account-info__arrow-menu pad_10"/>
                                    </div>
                                </div>
                    
                                </div>
                            </li>
                            <div className="transformable-profiles profiles_container" style={this.state.isShowProfiles ? {height : "100%", opacity : '1'} : {}}>
                                <div className={this.state.isShowProfiles ? "block" : "not_display"} ng-style="profilesListStyle">
                                    <div onClick={this.addAccountClicked} className="add-account-btn nav__item" >
                                        <img src={add}/>
                                        <div className="account-info__action account-info__action_add">{strings.ADD_ACCOUNT}</div>
                                    </div>
                                    {this.accounts && this.accounts.length > 0 && this.accounts.map((item, index) => (
                                        <MenuLogin item={item} index={index} key={index}/>
                                    ))}
                                    {/* <my-profiles></my-profiles> */}
                                </div>                    
                            </div>
                    
                    
                            <li><a className={window.location.pathname == "/promotion" ? "nav__item active_nav" : "nav__item"} href="/promotion">{strings.MENU_1}</a></li>
                            <li><a className={window.location.pathname == "/social" ? "nav__item active_nav" : "nav__item"} href="/social">Продвижение соцсетей 💥</a></li>

                            <li><a className={window.location.pathname == "/earn" ? "nav__item active_nav" : "nav__item"} href="/earn">{strings.MENU_2}</a></li>
                            {/* <li><a className={window.location.pathname == "/work" ? "nav__item active_nav" : "nav__item"} href="/work">{strings.MENU_4}</a></li> */}
                           
                           
                            {/* <li><a className="nav__item" href="http://blog.instarlike.com">{strings.MENU_5}</a></li> */}
                            {/* <li><a className="nav__item menu_storis_color" href="http://in-stories.tilda.ws/">{strings.MENU_6} <span><img className="menu_icon_stories" src={icon_stories}/></span></a></li>
                            <li className="bot_20"><a className="nav__item menu_storis_color" href="https://upmob.ru/universaland">{strings.MENU_7} <span><img className="menu_icon_stories" src={icon_universal}/></span></a></li> */}
                            </ul>
                        </nav>
                    </div>
            
            
                <div className="pad_20">
                    <a className="license_text" href="/agreement">{strings.LICENSE}</a>
                    <p className="write_to_us">{strings.EMAIL_TO_WRITE_US}</p>
                    <p className="write_to_us">*Признана экстремистской организацией и запрещена на территории РФ.</p>
                </div>
              </div>
              {/* <div className="gift_container">
                  <div className="gift-gradient"/>
                  <div className="aside__gift">
                    <div className="gift_elements">
                      <img src={gift_banner} alt=""/>
                      <div className="gift__text_box">
                        <p className="gift__text">{strings.GIFT_1}<br/>{strings.GIFT_2}</p>
                        <a href="https://instarlike.com/invite-friends" className="gift__btn">{strings.LEARN_MORE}</a>
                      </div>
          
                    </div>
          
                  </div>
              </div> */}
              
            
          
          </div>
        );
    }
}
export default MenuAndroid;