import React from 'react';
import screenshot from '../custom_icons/screenshot.svg'
import close from '../custom_icons/close-tooltip.svg'
import screenshot_error from '../custom_icons/screenshot_error.svg'
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import { getRequestUrl, getHttpParams } from '../../utils/Utils';
import { getRequestParams } from './utils_android/CookieHelper';
var strings = getLocalizedStrings()
var img = new Image; 
var fr = new FileReader;
var pickedFile = {}
class ScreenshotDialog extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            pickedImageUrl: "",
            isImageLoading : false,
            isUploading : false,
            isShowError : false
        }
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.fileUpload = React.createRef();
        this.pickImage = this.pickImage.bind(this);
    }
    fileChangedHandler(event) {
        if(event && event.target.files && event.target.files.length > 0){
            this.uploadFile(event.target.files[0])
        }
       
    }

    uploadFile = (file) => {
        console.log("file data = " + file)
        this.setState({isImageLoading : true})
        pickedFile = file
        fr.onload = this.onLoadedFileReader
        fr.readAsDataURL(file);
    }
    onImgLoaded = () => {
        this.uploadToServer(pickedFile)
    }
    onLoadedFileReader = () => {    
        img.onload = this.onImgLoaded
        img.src = fr.result;
    }
    pickImage() {
        this.fileUpload.current.click();
    }
    uploadToServer = (file) => {
        this.setState({isUploading : true, isShowError : false})
        var data = new FormData()
        var params = getRequestParams()
        data.append('file1', file)
        data.append('photo_id', this.props.photo.photo_id)
        data.append('_id', this.props.photo._id)
        data.append('grade', '1')
        data.append('login', params.login)
        data.append('timestamp', this.props.recaptchaToken)
        fetch(getRequestUrl() + "instaLikeScreenShot", {
            method: 'POST',
            mimeType: "multipart/form-data",
            body: data
        }).then((response) => response.json())
        .then((responseData) => {
            console.log("instaLikeScreenShot data = " + JSON.stringify(responseData))
            if (responseData.response == 1){
                this.props.toogleDialog()
                this.props.loadNextOrder()
            } else {
                this.setState({isShowError : true})
            }
            this.setState({isUploading : false})
        })
        
    }
    render(){
      
            return (
                <div className="screenshot_bg">
                    <div style={this.state.isUploading ? {height : '284px'} : {}} className="screenshot_container">
                        <img onClick={this.props.toogleDialog} className="screenshot_close hover" src={close}/>
                        {!this.state.isUploading ? 
                        this.state.isShowError ? 
                        <div>
                            <img className="screenshot_icon" src={screenshot_error}/>
                            <p className="screenshot_title">{strings.SCREEN_ERROR_TITLE}</p>
                            <p className="screenshot_text">{strings.SCREEN_ERROR_TEXT}</p>
                            <p className="screenshot_text">{strings.SCREEN_ERROR_TEXT_2}</p>
                            <p onClick={this.pickImage} className="screenshot_text screenshot_gallery hover">{strings.UPLOAD_SCREEN_GALLERY}</p>
                            <input id="fileInput" className="custom-file-input hover" type="file" accept="image/*" onChange={this.fileChangedHandler} style={{ display: "none" }} ref={this.fileUpload}/>
                        </div> :
                        <div>
                            <img className="screenshot_icon" src={screenshot}/>
                            <p className="screenshot_title">{strings.UPLOAD_SCREEN_TITLE}</p>
                            <p className="screenshot_text">{strings.UPLOAD_SCREEN_TEXT}</p>
                            <p onClick={this.pickImage} className="screenshot_text screenshot_gallery hover">{strings.UPLOAD_SCREEN_GALLERY}</p>
                            <input id="fileInput" className="custom-file-input hover" type="file" accept="image/*" onChange={this.fileChangedHandler} style={{ display: "none" }} ref={this.fileUpload}/>
                        </div> : 
                        <div className="center_upload_screen">
                            <div style={{position : 'relative'}} className="lds-ring loader-earn-container">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <p className="screenshot_uploading_text">{strings.UPLOADING_TEXT}</p>
                        </div>}
                        
                    </div>  
                </div>
            ); 
        
        
    }
}
export default ScreenshotDialog;