import React from 'react';
import { getLocalizedLandingStrings } from './landing/LocalizedLanding';
import '../pages_android/landing/css/hamburgers.min.css'
import '../pages_android/landing/css/agency.css'


import main_features_gift from './landing/icons/main_features_gift.svg'
import main_features_person from './landing/icons/main_features_person.svg'
import main_features_lock from './landing/icons/main_features_lock.svg'
import features_accaunts from './landing/icons/features_accaunts.svg'
import features_target from './landing/icons/features_target.svg'
import features_bag from './landing/icons/features_bag.svg'

import arrow_circle_right from './landing/icons/arrow_circle_right.svg'
import instagram_logo from './landing/icons/instagram_logo.svg'
import vk_icon from './landing/icons/vk_icon.svg'
import screenshot_before from './landing/icons/screenshot_before.png'
import screenshot_after from './landing/icons/screenshot_after.png'
import icons_universal from './landing/icons/icons_universal.png'
import logo_instarlike from './landing/icons/logo_instarlike.svg'
import Header from './landing/Header';
import AbousUsPlashka from './landing/AbousUsPlashka';
import RoundedPlashka from './landing/RoundedPlashka';
import social_telegram from './landing/icons/social_telegram.svg'
import stories_land_icon from './landing/icons/stories_land_icon.svg'

import HowItWorks from './landing/HowItWorks';
import Corousel from './landing/Corousel';
var strings = getLocalizedLandingStrings()
class Landing extends React.Component {
    constructor(props){
        super(props)
       this.state = {
           isShowNext : true
       }
            this.openImprovement = this.openImprovement.bind(this);
            this.howImprove = this.howImprove.bind(this);
            this.partner = this.partner.bind(this);
            this.other = this.other.bind(this);
            this.openStories = this.openStories.bind(this);
    }
    openAuth = () => {
        window.location.href = '/auth'
    }
    openPartner = () => {
        window.open('http://blog.instarlike.com/2020/04/16/partnerskaya-programma/', '_blank')
    }
    openInstagram = () => {
        window.open('https://www.instagram.com/in.starlike/', '_blank')
    }
    openVK = () => {
        window.open('https://vk.com/tagstar', '_blank')
    }
    openPolicy = () => {
        window.open('https://instarlike.com/policy', '_blank')
    }
    openAgreement = () => {
        window.open('https://instarlike.com/agreement', '_blank')
    }
    findMore = () => {
        window.open('https://upmob.ru/universaland', '_blank')
    }
    openImprovement(){
        window.open('https://blog.instarlike.com', '_blank');
    }
    openStories(){
        window.open('http://in-stories.tilda.ws/', '_blank');
    }
    howImprove(){
        window.open('https://blog.instarlike.com/2020/07/04/как-заказать-накрутку/', '_blank');
    }
    partner(){
        window.open('https://blog.instarlike.com/2020/04/16/partnerskaya-programma-dlya-zarabotka/', '_blank');
    }
    other(){
        // window.open('https://upmob.ru/universaland', '_blank');
        window.open('https://upsocial.ru/', '_blank');
    }
    openTg = () => {
        window.open('https://t.me/instarlike', '_blank');
    }
   
    toogleNext = () => {
        this.setState({isShowNext : !this.state.isShowNext})
    }
    render(){
      
            return (
                <div id="backClick" className="relative body_back">
                    <div ng-controller="landingController" className="top-background">
                        <Header openTg={this.openTg} openStories={this.openStories} openImprovement={this.openImprovement} howImprove={this.howImprove} partner={this.partner} other={this.other} openAuth={this.openAuth}/>
                    </div>
                    <div className="main-background">
                        <div className="section-about back-section section_div" id="about_us">
                            <div className="container container-about">
                                <AbousUsPlashka image={main_features_gift} text={strings.FREE} description={strings.FREE_DESC}/>
                                <AbousUsPlashka image={main_features_person} text={strings.REAL_USERS} description={strings.REAL_USERS_DESC}/>
                                <AbousUsPlashka style={{marginBottom : '16px'}} image={main_features_lock} text={strings.NO_REGISTRATION} description={strings.NO_REGISTRATION_DESC}/>
                            </div>
                            <div className="container rounded-container">
                                <RoundedPlashka image={features_accaunts} text={strings.PROMOTE_MULTIPLE} description={strings.PROMOTE_MULTIPLE_DESC}/>
                                <RoundedPlashka image={features_target} text={strings.LOCATION_GENDER} description={strings.LOCATION_GENDER_DESC}/>
                                <RoundedPlashka image={features_bag} text={strings.PACKAGE_OFFERS} description={strings.PACKAGE_OFFERS_DESC}/>
                            </div>
                        </div>
                        <div className="wave-mobile back-section"/>
                        <HowItWorks openAuth={this.openAuth}/>

                        <div className="back-section back_div section_div" id="reviews">
                            <div className="container">
                                <div className="row relative">
                                    <div className="col-lg-12 text-center color_white">
                                        <p className="earn-money-top-text pad_bot_0">{strings.REVIEWS_TITUL}</p>
                                        <p className="top-text-plashka top_0">{strings.REVIEWS_TITUL_DESC}</p>
                                    </div>
                                    {/* <img className="image-right-landscape" src={arrow_right}/> */}
                                </div>
                                <Corousel/>
                                
                            </div>
                        </div>

                        <div className="back-section padding_0 section_div" id="cases">
                            <div className="container">
                                <div className="text_cases">
                                    <div className="flex_icons">
                                        <div onClick={this.openInstagram} className="flex_icons_container hover">
                                            <img className="insta_logo" src={instagram_logo}/>
                                        </div>
                                        <div onClick={this.openVK} className="flex_icons_container hover">
                                            <img className="insta_logo" src={vk_icon}/>
                                            
                                        </div>
                                    </div>
                                    <p className="our_insta_text">{strings.FOLLOW_NEWS}<br/>{strings.FOLLOW_NEWS_2}</p>
                                    <p className="top-text-plashka many_people">{strings.SO_MANY_PEOPLE}</p>
                                    <p className="earn-money-top-text">1 110 239</p>
                                    <p className="top-text-plashka cases_title top_64">{strings.CASES_OF_OUR_CLIENTS}</p>
                                </div>
                            </div>
                            <div className="screens-container">
                                <div className="before_screen">
                                    {/* <p className="before_text">{strings.BEFORE}</p> */}
                                    <img className="width_100" src={screenshot_before}/>
                                </div>
                                <div className="after_screen">
                                    {/* <p className="after_text">{strings.AFTER}</p> */}
                                    <img className="width_100" src={screenshot_after} title={strings.SCREEN_2_TITLE} alt={strings.SCREEN_2_ALT}/>
                                </div>
                            </div>
                            <div className="container screens-container-mobile">
                                <div className="swipe_mobile">
                                    <p className="opacity_05">{strings.SWIPE_RIGHT}</p>
                                    <img className="width_100" src={this.state.isShowNext ? screenshot_before : screenshot_after} title={strings.SCREEN_1_TITLE} alt={strings.SCREEN_1_ALT}/>
                                    <img onClick={this.toogleNext} className="right-arrow" src={arrow_circle_right} title={strings.SCREEN_2_TITLE} alt={strings.SCREEN_2_ALT}/>
                                </div>
                            </div>
                            <p className="result_text">{strings.RESULT_SCREENS}</p>
                            <div className="partner_plashka block_display">
                                <p className="partner_title">{strings.PARTNER_1}</p>
                                <p className="partner_text">{strings.PARTNER_2}</p>
                                <p onClick={this.openPartner} id="partner_btn" className="partner_btn hover">{strings.PARTNER_3}</p>
                            </div>
                            <div className="stories_container">
                                <img src={stories_land_icon} className="stories_land_icon center_stories"/>
                                <p className="stories_land_title">{strings.STORIES_TITLE}<br/>{strings.STORIES_TITLE_2}</p>
                                <p className="stories_land_text center_stories">{strings.STORIES_TEXT}</p>
                                <p onClick={this.openStories} className="partner_btn left_unset hover find_more_stories center_stories">{strings.FIND_MORE}</p>

                            </div>
                            <div className="other_social_container">
                                <img className="other_icon" src={icons_universal}/>
                                <div className="other_plashka">
                                    <div className="center_other">
                                        <p className="other_title">{strings.OTHER_TITLE_1}<br/>{strings.OTHER_TITLE_2}<br/>{strings.OTHER_TITLE_3}</p>
                                        <p onClick={this.findMore} className="partner_btn left_unset hover">{strings.FIND_MORE}</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="footer">
                                <div>
                                    <img src={logo_instarlike}/>
                                    <p style={{marginTop : '16px'}} onClick={this.openAgreement} className="black_navbar_text right_32 hover">{strings.USER_AGREEMENT}</p>
                                    <p onClick={this.openPolicy} className="black_navbar_text right_32 hover">{strings.POLICY}</p>
                                    <p className="text_opacity instarlike_year">© 2019-2024 © Instarlike</p>
                                </div>
                                <div className="footer_32">
                                    {/* <p onClick={this.openImprovement} className="black_navbar_text right_32 hover">{strings.BLACK_NAVBAR_1}</p>
                                    <p onClick={this.howImprove} className="black_navbar_text right_32 hover">{strings.BLACK_NAVBAR_2}</p>
                                    <p onClick={this.partner} className="black_navbar_text right_32 hover">{strings.BLACK_NAVBAR_3}</p> */}
                                    <p onClick={this.other} className="black_navbar_text hover">{strings.BLACK_NAVBAR_4}</p>
                                </div>
                                <div className="footer_32">
                                    <p className="text_opacity">{strings.SERVICE_NEWS}</p>
                                    <p className="black_navbar_text">admin@instarlike.com</p>
                                    <div className="flex">
                                        <div onClick={this.openInstagram} className="footer_icons hover">
                                            <img className="footer_icon_height" src={instagram_logo}/>
                                        </div>
                                        <div onClick={this.openVK} className="footer_icons hover">
                                            <img className="footer_icon_height" src={vk_icon}/>
                                        </div>
                                        <div onClick={this.openTg} className="hover">
                                            <img className="footer_icon_height" src={social_telegram}/>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                <div className="wrap-account btn wrap_acc_btn footer_32 footer_last">
                                    <img className="wrap_acc_btn_logo" src={instagram_logo}/>
                                    <p onClick={this.openAuth} className="wrap_acc_btn_text">{strings.WRAP_ACC_BTN}</p>
                                </div>
                            </div>
                            {/* <div className="last-element">
                                <p>2019 - 2020 © Instarlike</p>
                                <p className="top_16">admin@instarlike.com</p>
                            </div> */}
                        </div>
                        <div className="bottom-button-wrap">
                            <button onClick={this.openAuth} className="wrap-account btn top-32 fixed-bot-btn" type="button" name="button">{strings.PROMOTE_MY_ACCOUNT}</button>
                        </div>
                    </div>
                    <div id="vk_community_messages"></div>
                </div>
            ); 
        
        
    }
}
export default Landing;