import React from 'react';
import { getLocalizedStrings } from './utils/LocalizedStrings';
import '../pages_android/custom_views/shop_views/shop.css'
import { getUserId, getRequestParams } from './custom_views/insta_custom_views/utils_android/CookieHelper';
import { getRequestUrl, getHttpParams, declension } from './utils/Utils';
import Confirm from './custom_views/shop_views/Confirm';
import stars_mob from '../pages_android/custom_views/custom_icons/stars_mob.svg'
import BuyCoinsBtn from './custom_views/shop_views/BuyCoinsBtn';
import { calcStandartPrice, calcCoins } from './custom_views/shop_views/ShopHelper';
import BuyDescription from './custom_views/shop_views/BuyDescription';
import Arrow from './custom_views/shop_views/Arrow';
import CalcInput from './custom_views/shop_views/CalcInput';
import Calculator from './custom_views/shop_views/Calculator';
import payment_methods from './custom_views/custom_icons/payment_methods.png'
import payment_methods_desktop from './custom_views/custom_icons/payment_methods_desktop.svg'
import payment_methods_mobile from './custom_views/custom_icons/payment_methods_mobile.svg'
import { isNull } from 'util';
import NewDesignShopButtons from './custom_views/shop_views/NewDesignShopButtons';
import { getUrlParameter } from './custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
var strings = getLocalizedStrings()
class Shop extends React.Component {
    constructor(props){
        super(props)
        this.state = {
           isShowConfirm : false,
           isShowAnotherAmount : false,
           enteredCoinsDecl : strings.COIN_5,
           coinsET : 100,
           standartEnteredPrice : calcStandartPrice(100),
           saleEnteredPrice : calcCoins(100),

           calculatedCoins : 0,
           lang : 'ru',
           confirmPrice : 0,
           confirmCoins : 0,
           fakePrice : 0
        }
        this.coinsETChanged = this.coinsETChanged.bind(this);
        this.buyAnotherAmountClicked = this.buyAnotherAmountClicked.bind(this);
        this.toogleConfirm = this.toogleConfirm.bind(this);
    }
    
    coinsETChanged(event){
        if(event){
            var value = event.target.value
            this.setState({coinsET : value, standartEnteredPrice : calcStandartPrice(value), saleEnteredPrice : calcCoins(value), enteredCoinsDecl : declension(value, strings.COIN, strings.COIN_2, strings.COIN_5)})
        }
    }

    buyAnotherAmountClicked(isChecked){
        this.setState({isShowAnotherAmount : isChecked})
    }
    toogleConfirm(){
        this.setState({isShowConfirm : !this.state.isShowConfirm})
    }
    buyCoins = (coins, price, fakePrice) => {
        this.setState({isShowConfirm : true, confirmPrice : price, confirmCoins : coins, fakePrice : fakePrice})
        // if (price != undefined && price > 0) {
        //   window.location.href = 'https://unitpay.ru/pay/148181-640f9?sum=' + price + '&desc= ' + strings.BUY_COINS_UNITPAY + " " + coins + " " + declension(coins, strings.COIN, strings.COIN_2, strings.COIN_5) + '&account=' + getUserId() + "&backUrl=https://instarlike.com/promotion" + "&locale=" + this.state.lang;
        // }
    }
    getLanguage = () => {
        var params = getRequestParams();
        fetch(getRequestUrl() + "getLanguage", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1) {
                this.setState({lang : responseData.language})
            }
        })
    }
    componentDidMount(){
        this.getLanguage()
        if (isNull(getUserId())){
            window.location.href = "/auth?isAddAccount=true"
        }
       
    }
    
    render(){
      
            return (
                <div className="promotion-page">
                {this.state.isShowConfirm ? <Confirm count_money_r={this.props.count_money_r} fakePrice={this.state.fakePrice} lang={this.state.lang} price={this.state.confirmPrice} coins={this.state.confirmCoins} toogleConfirm={this.toogleConfirm}/> :
                <div>
                    <h2 className="h1 main__header_profile">{strings.BUY_COINS_TITLE}</h2>
                    <div className="new-up-balance">
                        {/* <p className="shop_sale_text">{strings.SHOP_SALE_TEXT}</p> */}
                        <div className="buy-buttons-container">
                            <div className="buy-grid-container">
                                <img className="full_width_m" src={stars_mob} />
                                <div className="buy-title-container">{strings.BUY_MORE_CALC} <br/>{strings.PAY_LESS_CALC}</div>
                            </div>
                            <div>
                            <NewDesignShopButtons buyCoins={this.buyCoins}/>

                            </div>
                        </div>

                        <div>
                            <Arrow arrowClicked={this.buyAnotherAmountClicked} title={strings.BUY_ANOTHER_CALC}/>
                            {this.state.isShowAnotherAmount && <div className="buy-another-container transformable-profiles">
                                <input value={this.state.coinsET} onChange={this.coinsETChanged} type="number" min="1" className="input add-subscribe-form__input input-another-container" name="Count"/>
                                <p className="buy-another-right-text">{this.state.enteredCoinsDecl}</p>
                                <BuyCoinsBtn buyCoins={this.buyCoins} coinsCount={this.state.coinsET} coins={strings.BUY} fullPrice={this.state.standartEnteredPrice} realPrice={this.state.saleEnteredPrice}/>
                            </div>}
                        </div>
                        <BuyDescription/>
                        <Calculator buyCoins={this.buyCoins}/>
                        <div className="line_payment"/>
                        <img className="desctop_payments" src={payment_methods}/>
                        <img className="mobile_payments" src={payment_methods}/>
                    </div>
                </div>}
                    
                </div>
            ); 
        
        
    }
}
export default Shop;
