
  import React from 'react';
  import { getLocalizedLandingStrings } from './LocalizedLanding';
  import logo_white from '../landing/icons/logo_white.svg'
  import universe from '../landing/icons/universe.svg'
  import instagram_logo from '../landing/icons/instagram_logo.svg'
  import social_telegram from '../landing/icons/social_telegram.svg'
  import icon_stories from '../custom_views/custom_icons/icon_stories.svg'
  import vk_icon from '../landing/icons/vk_icon.svg'
  import boom from '../landing/icons/boom.svg'
  import black_navbar_icon from '../landing/icons/black_navbar_icon.svg'
  import { Link, animateScroll as scroll } from "react-scroll";
  import MobileLandingMenu from './MobileLandingMenu';
    var strings = getLocalizedLandingStrings()
    
    class Header extends React.Component {
        constructor(props){
            super(props)
            this.isCollapseNavbar = false
            this.state = {
                isCollapseNavbar : false,
                isMenuPressed : false
            }
            this.isMobile = (window.screen.width <= 768)
            
            this.openInsta = this.openInsta.bind(this);
            this.openVK = this.openVK.bind(this);
        }
        componentDidMount(){
            window.addEventListener('scroll', this.handleScroll);
        }
        componentWillUnmount(){
            window.removeEventListener('scroll', this.handleScroll)
        }
        openInsta(){
            window.open('https://www.instagram.com/in.starlike/', '_blank');
        }
        openVK(){
            window.open('https://vk.com/tagstar', '_blank');
        }
        handleScroll = (event) => {
            let scrollTop = window.scrollY
            if (scrollTop >= 48 && !this.isCollapseNavbar){
                this.isCollapseNavbar = true
                this.setState({isCollapseNavbar : true})
            } else if(scrollTop < 48){
                if (this.isCollapseNavbar){
                    this.isCollapseNavbar = false
                    this.setState({isCollapseNavbar : false})
                }
            }
        }
        menuClicked = () => {
            
            this.setState({isMenuPressed : !this.state.isMenuPressed})
        }
        closeMenu = () => {
            console.log("closeMenu")
            this.setState({isMenuPressed : false})
        }
        
        render(){
                return (
                    <div>
                        <div className="black_navbar">
                            <div className="container-flex-just-cont width_100_black">
                                <div className="flex">
                                    <p onClick={this.props.openImprovement} className="black_navbar_text right_32 hover">{strings.BLACK_NAVBAR_1}</p>
                                    <p onClick={this.props.howImprove} className="black_navbar_text right_32 hover">{strings.BLACK_NAVBAR_2}</p>
                                    <p onClick={this.props.partner} className="black_navbar_text right_32 hover">{strings.BLACK_NAVBAR_3}</p>
                                    <div className="flex"> 
                                        <p onClick={this.props.openStories} className="black_navbar_text hover">{strings.STORIES_MENU}</p>
                                        <img className="menu_icon_stories_land" src={icon_stories}/>
                                    </div>
                                    <p onClick={this.props.other} className="black_navbar_text  hover">{strings.BLACK_NAVBAR_4}</p>
                                    <img className="right_32 left_m_8_menu" src={boom}/>
                                </div>
                                <div className="flex">
                                    <img onClick={this.openVK} className="icon_24_img right_24 hover" src={vk_icon}/>
                                    <img onClick={this.openInsta} className="icon_24_img hover right_24" src={instagram_logo}/>
                                    <img onClick={this.props.openTg} className="icon_24_img hover" src={social_telegram}/>
                                </div>
                            </div>
                        </div>
                        <div onClick={this.props.other} className="black_navbar black_navbar_mobile">
                            <div className="jus_cont width_100_black">
                                <div className="flex">
                                    <p className="black_navbar_text  hover">{strings.BLACK_NAVBAR_4}</p>
                                    <img className="right_32 left_m-8" src={boom}/>
                                </div>
                                <img src={black_navbar_icon}/>
                            </div>
                        </div>
                        {this.state.isMenuPressed && <MobileLandingMenu openTG={this.props.openTg} openStories={this.props.openStories} openAuth={this.props.openAuth} openInsta={this.openInsta} openVK={this.openVK} openImprovement={this.props.openImprovement} howImprove={this.props.howImprove} partner={this.props.partner} other={this.props.other} closeMenu={this.closeMenu} isMenuPressed={this.state.isMenuPressed}/>}
                        <nav style={this.state.isCollapseNavbar ? {position : 'fixed', marginTop : 0} : {position : 'absolute'}} className={this.state.isCollapseNavbar ? "navbar navbar-expand-lg navbar-dark fixed-top navbar-height navbar-shrink" : "navbar navbar-expand-lg navbar-dark fixed-top navbar-height"} id="mainNav">
                                
                                    
                                    <div className="container padding_0 flex">
                                        <img className="navbar-brand js-scroll-trigger navbar-icon"/>
                                        <button onClick={this.menuClicked} className={this.state.isMenuPressed ? "navbar-toggler navbar-toggler-right hamburger hamburger--collapse btn_togle is-active" : "navbar-toggler navbar-toggler-right hamburger hamburger--collapse btn_togle"} type="button" >
                                            <span className="hamburger-box">
                                                <span className="hamburger-inner"></span>
                                            </span>
                                        </button>
                                    
                                        <div className="collapse navbar-collapse navbar-opened-plashka" >
                                            <ul className="navbar-nav text-uppercase ml-auto">
                                                <li className="nav-item">
                                                    <Link smooth={"true"} className="nav-link js-scroll-trigger navbar-text" to="about_us">{strings.ABOUT_US}</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link smooth={"true"} className="nav-link js-scroll-trigger navbar-text" to="how-it-works">{strings.HOW_IT_WORKS}</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link smooth={"true"} className="nav-link js-scroll-trigger navbar-text" to="reviews">{strings.REVIEWS}</Link>
                                                </li>
                                                {/* <li className="nav-item">
                                                    <a smooth={"true"}className="nav-link js-scroll-trigger navbar-text" href="http://blog.instarlike.com">{strings.ADVICES_WRAP}</a>
                                                </li> */}
                                                <li style={{marginRight : '-14px'}} className="nav-item">
                                                    <button onClick={this.props.openAuth} className="btn nav-link js-scroll-trigger wrap-account-navigation my-button-wrap btn_promote"  type="button" name="button">{strings.PROMOTE_MY_ACCOUNT}</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                
                                
                            </nav>
                        <header className="masthead header-background back-section">
                            <div className="container">
                                <h1 className="insta_get_like">{strings.INSTA_PROMO_ACC}</h1>
                            
                                <div className="top-container">
                                    <div className="top-site-description">
                                        <div className="intro-text text-left">
                                            <img className="logo-white-img" src={logo_white} />
                                            <h1 className="inta_get_likes_web">{strings.INSTA_PROMO_ACC}</h1>
                                            <p className="top-32 opacity_08">{strings.GET_LIKES}</p>
                                            <p className="top-32 opacity_08">{strings.JUST_CHOOSE}</p>
                                            <button onClick={this.props.openAuth} className="wrap-account btn top-32" type="button" name="button">{strings.PROMOTE_MY_ACCOUNT}</button>
                                        </div>
                                    </div>
                            
                                    <img className="universe" src={universe} />
                                    <div className="text-left intro-text-mobile">
                                        <p className="top-32 opacity_08">{strings.GET_LIKES}</p>
                                        <p className="top-32 opacity_08">{strings.JUST_CHOOSE}</p>
                                    </div>
                            
                                </div>
                            </div>
                        </header>
                    </div>
                ); 
            
            
        }
    }
    export default Header;
    