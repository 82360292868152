import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
var strings = getLocalizedStrings()

class Arrow extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isArrowToogled : false
        }
        this.arrowClicked = this.arrowClicked.bind(this);
    }
    arrowClicked(){
        var isChecked = !this.state.isArrowToogled
        this.setState({isArrowToogled : isChecked})
        this.props.arrowClicked(isChecked)
    }
    render(){
        return (
            <div onClick={this.arrowClicked} className="get_followers_settings">
                <p className="font_16">{this.props.title}</p>
                <i style={this.state.isArrowToogled ? {transform: 'rotate(0deg)'} : {}} className="get_followers__arrow-menu arrow_rot"></i>
            </div>
        ); 
        
        
    }
}
export default Arrow;
