import React from 'react';
import { getLocalizedLandingStrings } from './LocalizedLanding';
  
    var strings = getLocalizedLandingStrings()
    
    class AbousUsPlashka extends React.Component {
        constructor(props){
            super(props)
        }
        
        render(){
                return (
                    <div className="about_us_plashka">
                        <img className="img-plashka" src={this.props.image}/>
                        <h2 className="top-text-plashka">{this.props.text}</h2>
                        <p style={this.props.style ? this.props.style : {}} className="regular-text-plashka">{this.props.description}</p>
                    </div>
                ); 
            
            
        }
    }
    export default AbousUsPlashka;
    