import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
var strings = getLocalizedStrings()

class BuyDescription extends React.Component {
    constructor(props){
        super(props)
    }
   
    render(){
        return (
                <div>
                    <p className="buy-description-title">{strings.WHAT_BUY_CALC}</p>
                    <div className="buy-descr-coins">
                        <p>1 {strings.LIKE_CALC}</p>
                        <p>2 {strings.COIN_2}</p>
                    </div>
                    <div className="buy-descr-coins">
                        <p>1 {strings.FOLLOWER_CALC}</p>
                        <p>10 {strings.COIN_5}</p>
                    </div>
                    <div className="buy-descr-coins">
                        <p>1 {strings.COMMENT_CALC}</p>
                        <p>10 {strings.COIN_5}</p>
                    </div>
                    <div className="buy-descr-coins">
                        <p>1 {strings.VIEW_CALC}</p>
                        <p>10 {strings.COIN_5}</p>
                    </div>
                </div>
        ); 
        
        
    }
}
export default BuyDescription;
