import React from 'react';
import empty_image_insta from './insta_icons_tasks/empty_image_insta.svg'
import heart_insta_android from '../custom_icons/heart.svg'
import chevron_next_insta_android from '../custom_icons/chevron_next.svg'
import BaseEarnTask from './BaseEarnTask';
import qs from 'querystring'
import {getRequestParams} from './utils_android/CookieHelper'
import { ReCaptcha } from 'react-recaptcha-v3'
import { getTimestampToken } from '../../utils/Utils';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
var strings = getLocalizedStrings()
class PhotoTask extends BaseEarnTask {
    constructor(props){
        super(props)
        this.state = {
            currentDescription : "",
            isUpdateCaptcha : false,
            isImgError : false
        }
        this.startTaskTime = -1
        window.photoTask = this
        this.toogleImgError = this.toogleImgError.bind(this);
    }
    
    onResume(){
        this.setState({isUpdateCaptcha : true})
        console.log("PhotoTask on resume")
    }
    toogleImgError(){
        this.setState({isImgError : true})
    }
    verifyCallback = (recaptchaToken) => {
        this.setState({isUpdateCaptcha : false})
        console.log("verifyCallback recaptchaToken = " + recaptchaToken)
        if (this.startTaskTime != -1){
            var currentTime = new Date().getTime()
            if (currentTime - 300 >= this.startTaskTime){
                this.props.showLoader()
                this.setState({isImgError : false})
                this.instaLike(1, recaptchaToken)
            }
        }
    }
    doTask = () => {
        this.startTaskTime = new Date().getTime() 
        this.openUrl(this.props.currentPhotoTask.insta_url, 1)
    }
    
    skip = () => {
        this.setState({isImgError : false})
        this.skipOrder(false, this.props.currentPhotoTask.photo._id)
    }
  
    render() {
        return (
            <div className="rate-content__item">
                    <div className="photo_task_container">
                        <img width="64" height="64" className="placeholder_task icon_64"
                            src={empty_image_insta} alt=""/>
                        <img style={this.state.isImgError ? {opacity : '0'} : {}} onError={this.toogleImgError} className="image_task_holder" src={this.props.currentPhotoTask.photo.photo_path_standart}
                            alt="" width="360" height="360"/>
                        <img onClick={this.skip} src={chevron_next_insta_android} className="arrows__item arrows__item_next arrow_item_follower"></img>
                        {/* <p onClick={this.appealClicked} className="appeal_text appeal_text_bg">{strings.APPEAL}</p> */}
                    </div>


                    <div className="rate-content__actions">
                        {this.state.isShowError && <p className="block_account">{strings.ERROR_LIKE_1}<br/>{strings.ERROR_LIKE_2}</p>}
                        <div onClick={this.doTask} className="btn-follow top_10">
                            <img className="btn_task_icon" src={heart_insta_android} alt=""/>
                            {strings.LIKE_BUTTON}
                        </div>
                        <div className="coins_rub_lashka rub_plashka">
                            <span className="subscribe_follow_reward right_4">+{this.props.reward} {strings.COIN}</span>
                            <span className="subscribe_follow_reward left_4">+{this.props.currentPhotoTask.earn_money}₽</span>
                        </div>
                    </div>
                    {this.state.isUpdateCaptcha && <ReCaptcha
                        sitekey={getTimestampToken()}
                        action='homepage'
                        verifyCallback={this.verifyCallback}/>
                    } 
                </div>
        );
    }
}
export default PhotoTask;
