import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import '../tooltips/tooltips.css'
import pink_close from '../custom_icons/pink_close.svg'
import share from '../custom_icons/share.svg'
import ios_home_plus from '../custom_icons/ios_home_plus.svg'
var strings = getLocalizedStrings()

class IosHomeScreen extends React.Component {
    constructor(props){
        super(props)
    }
    
    render(){
      
            return (
                <div className="tooltip-how-do-it transformable-profiles ios_home_screen_plashka">
                    <img onClick={this.props.toogleSceenTooltip} className="close_ios hover" src={pink_close}/>
                    <div className="pad_25">
                    <p className="ios_home_screen_title">{strings.FAST_WRAPPING}</p>
                    <p className="ios_home_screen_text">{strings.FAST_WRAPPING_CLICK} 
                        <img className="ios_home_image" src={share}/> {strings.FAST_WRAPPING_PICK}<br/> <img className="ios_home_image"
                        src={ios_home_plus}/> {strings.FAST_WRAPPING_HOME}
                    </p>
            
                    </div>
                    <div className="ios_home_arrow"></div>
            
                </div>
            ); 
        
        
    }
}
export default IosHomeScreen;
