import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import '../tooltips/tooltips.css'
import close from '../custom_icons/close.svg'
import promo_discount from '../custom_icons/promo_discount.svg'
var strings = getLocalizedStrings()

class SessionPromotionTooltip extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isShowBanner : false
        }

    }
    componentDidUpdate(prevProps){
        if(prevProps != this.props){
            this.setState({isShowBanner : this.props.isShowSessionBanner})
        }
        console.log("componentDidUpdate isShowBanner = " + this.props.isShowBanner)
    }
    toogleTooltip = () => {
        this.setState({isShowBanner : !this.state.isShowBanner})
    }
    doFirstTask = () =>{
        window.location.href = '/earn'
    }
    render(){
      
            return (
                <div onClick={this.toogleTooltip} className="private-profile session_promotion_container">
                    {this.props.acceptText}<br/><strong className="hover">{strings.PROMOTION_ACCEPT_MORE}</strong>
                    
                    {this.state.isShowBanner && <div className="session_banner">
                        <div onClick={this.toogleTooltip} className="tooltip_bg"></div>
                        <i className="session_banner_arrow"></i>
                        <div className="session_banner_line"></div>
                        <div className="session_banner_container">
                            <p className="session_banner_title">{strings.SESSION_DIALOG_TITLE}</p>
                            <p className="session_banner_text">{strings.SESSION_DIALOG_TEXT_1}</p>
                            <p className="session_banner_text">{this.props.tooltipText}</p>
                            <p onClick={this.doFirstTask} className="session_banner_btn hover">{strings.DO_TASKS}</p>
                        </div>
                    </div>}
                </div>
            ); 
        
        
    }
}
export default SessionPromotionTooltip;

