import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import '../tooltips/tooltips.css'
import pink_close from '../custom_icons/pink_close.svg'
import share from '../custom_icons/share.svg'
import logo_android from '../custom_icons/logo_android.svg'
var strings = getLocalizedStrings()

class AndroidHomeScreen extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isShow : true
        }
    
    }
    addToHomeScreenAndroid = () => {
        console.log("addToHomeScreenAndroid = " + this.deferredPrompt)
        this.setState({isShow : false})
        
        if (this.deferredPrompt == undefined){
            return
        }
        this.deferredPrompt.prompt();
          
        this.deferredPrompt.userChoice.then(this.coiceResultFunc);
    }
    coiceResultFunc = (choiceResult) => {
        this.props.showFirsTaskTooltip()
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }
            this.deferredPrompt = null;
        
    }
    beforeinstallprompt = (e) => {
        e.preventDefault();
        this.deferredPrompt = e      
    }
    componentDidMount(){
        window.addEventListener('beforeinstallprompt', this.beforeinstallprompt);
       
    }
    render(){
      
            return (
                <div style={this.state.isShow ? {} : {display : 'none'}} className="transformable-profiles android_home_screen_plashka">
                    <img onClick={this.props.toogleSceenTooltip} className="close_android" src={pink_close} />
                    <div className="pad_25">
                        <div className="flex">
                            <img className="android_logo" src={logo_android}/>
                            <div>
                                <p className="android_home_screen_title">
                                    {strings.FAST_WRAPPING_ANDROID}<br/>{strings.FAST_WRAPPING_ANDROID_2}</p>
                                <p className="android_home_screen_text">instarlike.com</p>
                            </div>
                        </div>
                        <div onClick={this.addToHomeScreenAndroid} className="android_home_screen_button">
                            <p className="android_home_screen_button_text">{strings.FAST_WRAPPING_ANDROID_3}
                            </p>
                        </div>
                    </div>
                </div>
            ); 
        
        
    }
}
export default AndroidHomeScreen;
