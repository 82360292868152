import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import '../tooltips/tooltips.css'
import close from '../custom_icons/close.svg'
var strings = getLocalizedStrings()

class AuthTooltip extends React.Component {
    constructor(props){
        super(props)

    }
    closeTooltip = () =>{
        this.props.toogleAuthTooltip()
    }
    render(){
      
            return (
                <section className="question_descr auth_tooltip">
                    <img onClick={this.closeTooltip} className="close_auth_tooltip hover" src={close}/>
                    <h2 className="h1 main__header">{strings.QUESTION_TITLE}</h2><br/>
                    <p>{strings.QUESTION_TEXT}</p>
                    <br/>
                    <p>{strings.QUESTION_TEXT_2}</p>
                    <p onClick={this.closeTooltip} className="btn-get-money bottom_left_ok">{strings.QUESTION_OK}</p>
                </section>
                    
            ); 
        
        
    }
}
export default AuthTooltip;
