import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import { getButtonsStyle, getIsTagsEuro } from '../../utils/Utils';
var strings = getLocalizedStrings()

class BuyCoinsBtn extends React.Component {
    constructor(props){
        super(props)
        this.confirmPayment = this.confirmPayment.bind(this);
    }
    confirmPayment(){
        this.props.buyCoins(this.props.coinsCount, this.props.realPrice, this.props.fullPrice)
    }
    render(){
        const isTagsEuro = getIsTagsEuro()
        return (
            <div onClick={this.confirmPayment} className="btn-buy-coins hover">
                <div  style={getButtonsStyle()} className="left-buy-coins">
                    <p className="left-text-buy">{this.props.coins}</p>
                </div>
                <div className="right-buy-coins">
                    <p className="right-text-buy-1">{strings.FOR_CALC} {this.props.fullPrice}₽.</p>
                    <p className="right-text-buy-2">{strings.FOR_CALC} {this.props.realPrice}₽</p>
                </div>
            </div> 
        ); 
        
        
    }
}
export default BuyCoinsBtn;
