import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import '../tooltips/tooltips.css'
import close from '../custom_icons/close.svg'
var strings = getLocalizedStrings()

class NotEnoughtCoins extends React.Component {
    constructor(props){
        super(props)
        this.getMoreCoins = this.getMoreCoins.bind(this);
    }
    getMoreCoins() {
        window.location.href = '/up-balance'
    }
    render(){
      
            return (
                <div className="tooltip__container tooltip-top rate-content__tooltip-wrapper tooltip-not_enought_coins">
                    <i style={this.props.arrowStyle ? this.props.arrowStyle : {top : 0}} className="tooltip__arrow rate-content__tooltip-arrow follow-tooltip-coins top_a_0"></i>
                    <p className="size_16">{this.props.text}</p>
                    <p onClick={this.getMoreCoins} className="btn-get-money bot_m_0 hover">{strings.BUY_COINS}</p>
                </div>
                    
            ); 
        
        
    }
}
export default NotEnoughtCoins;
