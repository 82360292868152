import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import { declension, getRequestUrl, getHttpParams } from '../../utils/Utils';
import { getRequestParams } from '../insta_custom_views/utils_android/CookieHelper';
import { getStandartUrl, getLowUrl, getIsPhoto, getSource, updateProgress, getSource2 } from '../../utils/PhotoItemHelper';
var strings = getLocalizedStrings()
class OrderBase extends React.Component {
    constructor(props){
        super(props)
      
        this.state = {
            notEnoughtCoins : "",
            isShowNotEnought : false,
            isLoading : false,
            isShowSessionError : false,

            isLikes : true,
            likes : 10,
            isComments : (window.screen.width <= 768 ? true : false),
            comments : 0,
            isViews : (window.screen.width <= 768 ? true : false),
            views : 0,

            sum : 20,
            likesDecl : strings.LIKE_5,
            commentsDecl : strings.COMMENT_5,
            viewsDecl : strings.VIEW_5,
        }
        this.likesSwitchChanged = this.likesSwitchChanged.bind(this)
        this.likesValueChanged = this.likesValueChanged.bind(this)

        this.commentsSwitchChanged = this.commentsSwitchChanged.bind(this)
        this.commentsValueChanged = this.commentsValueChanged.bind(this)

        this.viewsSwitchChanged = this.viewsSwitchChanged.bind(this)
        this.viewsValueChanged = this.viewsValueChanged.bind(this)
        this.createOrder = this.createOrder.bind(this)
       
    }
    likesSwitchChanged(event){
        if (event){
            var isChecked = event.target.checked
            this.setState({isLikes : isChecked})
            this.calcSum(this.state.likes, this.state.comments, this.state.views, isChecked, this.state.isComments, this.state.isViews)
        }
    }
    commentsSwitchChanged(event){
        if (event){
            var isChecked = event.target.checked
            this.setState({isComments : isChecked})
            this.calcSum(this.state.likes, this.state.comments, this.state.views, this.state.isLikes, isChecked, this.state.isViews)
        }
    }
    viewsSwitchChanged(event){
        if (event){
            var isChecked = event.target.checked
            this.setState({isViews : isChecked})
            this.calcSum(this.state.likes, this.state.comments, this.state.views, this.state.isLikes, this.state.isComments, isChecked)
        }
    }
    likesValueChanged(event){
        if (event){
            var value = event.target.value
            this.setState({likes : value, likesDecl : declension(value, strings.LIKE, strings.LIKE_2, strings.LIKE_5)})
            this.calcSum(value, this.state.comments, this.state.views, this.state.isLikes, this.state.isComments, this.state.isViews)
        }
    }
    commentsValueChanged(event){
        if (event){
            var value = event.target.value
            this.setState({comments : value, commentsDecl : declension(value, strings.COMMENT, strings.COMMENT_2, strings.COMMENT_5)})
            this.calcSum(this.state.likes, value, this.state.views, this.state.isLikes, this.state.isComments, this.state.isViews)
        }
    }
    viewsValueChanged(event){
        if (event){
            var value = event.target.value
            this.setState({views : value, viewsDecl : declension(value, strings.VIEW, strings.VIEW_2, strings.VIEW_5)})
            this.calcSum(this.state.likes, this.state.comments, value, this.state.isLikes, this.state.isComments, this.state.isViews)
        }
    }
    calcSum = (like, comments, views, isLikes, isComments, isViews) => {
        var sum = 0
        if (isLikes){
            sum += like * 2
        }
        if (isComments){
            sum += comments * 10
        }
        if (isViews){
            sum += views * 10
        }
        this.setState({sum : sum})
    }
    createOrder() {
        if (this.state.sum > this.props.count_coins){
            this.setState({isShowNotEnought : true, notEnoughtCoins : (strings.NOT_ENOUGHT_COINS_TOOLTIP + " " + this.props.count_coins + " " + declension(this.props.count_coins, strings.COIN, strings.COIN_2, strings.COIN_5))})
        } else {
            this.setState({isLoading : true, isShowNotEnought : false, notEnoughtCoins : ""})
            this.setPhotoState(this.onLikesSuccess);
        }
    }
    onLikesSuccess = () => {
        this.addExtraComment(this.onCommentsSuccess);
    }
    onCommentsSuccess = () => {
        this.addExtraView(this.onViewsSuccess);
    }
    onViewsSuccess = () => {
        this.props.updateMoney(0, -this.state.sum)
        if (this.state.isLikes && this.state.likes > 0 && this.state.likes != ""){
            this.props.photo.our_info_photo = updateProgress(this.props.photo.our_info_photo, (this.state.likes * 2), false)
        }
        if (this.state.isComments && this.state.comments > 0 && this.state.comments != ""){
            this.props.photo.our_info_extra_2 = updateProgress(this.props.photo.our_info_extra_2, (this.state.comments * 10), true)
        }
        if (this.state.isViews && this.state.views > 0 && this.state.views != ""){
            this.props.photo.our_info_extra_1 = updateProgress(this.props.photo.our_info_extra_1, (this.state.views * 10), true)
        }
        this.props.orderCreated(this.props.photo, this.props.index)
        this.props.toogleTooltip()
        this.setState({isLoading : false})
    }
    sessionErrored = () => {
        this.setState({isLoading : false, isShowSessionError : true})
        this.props.sessionError()
        this.props.toogleTooltip()
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    setPhotoState = (success) => {
        if (!this.state.isLikes || this.state.likes <= 0 || this.state.likes == "") {
          success();
          return;
        }
        var params = getRequestParams();
        params.photo_path_standart = getSource2(this.props.photo);
        params.photo_path_low = getSource2(this.props.photo);
        params.is_active = 1;
        params.photo_id = this.props.photo.id;
        params.add_coins = (this.state.likes * 2);
        params.is_photo = getIsPhoto(this.props.photo);
       
        fetch(getRequestUrl() + "setPhotoState", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("setPhotoState = " + JSON.stringify(responseData))
            if (responseData.response == -100){
                this.sessionErrored()
            } else {
                success()
            }
            
        })
     
      }

     addExtraComment = (success) => {
        if (!this.state.isComments || this.state.comments <= 0 || this.state.comments == "") {
          success();
          return;
        }
        var params = getRequestParams();
        params.video_url = getSource2(this.props.photo);
        params.photo_url = getSource2(this.props.photo);
        params.media_id = this.props.photo.id;
        params.type = 2;
        params.add_coins = this.state.comments*10;
        params.avatar = "";
        fetch(getRequestUrl() + "addExtraOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("addExtraOrder = " + JSON.stringify(responseData))
            if (responseData.response == -100){
                this.sessionErrored()
            } else {
                success()
            }
            
        })
        
    
    
      }

    addExtraView = (success) => {
        if (!this.state.isViews || this.state.views <= 0 || this.state.views == "") {
          success();
          return;
        }
        var params = getRequestParams();
        params.video_url = "https://www.instagram.com/p/" + this.props.photo.code;
        params.photo_url = getSource2(this.props.photo);
        params.media_id = this.props.photo.id;
        params.type = 1;
        params.add_coins = this.state.views * 10;
        params.avatar = "";
        console.log("addExtraView params = " + JSON.stringify(params));
        fetch(getRequestUrl() + "addExtraOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("addExtraOrder = " + JSON.stringify(responseData))
            if (responseData.response == -100){
                this.sessionErrored()
            } else {
                success()
            }
            
        })
       
      }
    
}
export default OrderBase;