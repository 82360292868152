import React from 'react';
import { getLocalizedStrings } from '../utils/LocalizedStrings';
import Withdrow from './tooltips/Withdrow';
import { declension } from '../utils/Utils';
var strings = getLocalizedStrings()
class WorkLandscape extends React.Component {
    constructor(props){
        super(props)
       
    }
    
    render(){
      
            return (
                <div className="invite-friends-content">
                    <div className="invite-bonus bonus-left">
                    <h3 className="h3 invite-bonus__heading">{strings.YOU_EARNED}</h3>
                    <p className="invite-bonus__heading bonus_rub">{parseFloat(this.props.count_money_r).toFixed(2)}₽</p>
                    <span className="tooltip rate-content__tooltipm_0">
                        <p onClick={this.props.toogleWithdrow} className="btn-get-money">{strings.WITHDROW}</p>
                        {this.props.isShowWithdrow && <Withdrow showSessionError={this.props.showSessionError} count_money_r={this.props.count_money_r} toogleWithdrow={this.props.toogleWithdrow}/>}
                    </span>
                    <p>{strings.YOU_CAN_GET} <strong> {strings.COST_30}</strong> {strings.FOR_ALL_USERS}</p></div>
                    <div className="invite-bonus bonus-right">
                    <h3 className="h3 invite-bonus__heading">{strings.YOUR_BONUCES}</h3>
                    <p className="invite-bonus__heading bonus_rub">{this.props.referals_coins} <span className="bonus_coins">{this.props.declentionReferal}</span></p>
                    <p className="op_05">{this.props.friendsInvitedCount} {declension(this.props.friendsInvitedCount, strings.INVITE, strings.INVITE_2, strings.INVITE_5)} {strings.ACCEPTED}</p>
                    <p>{strings.YOU_CAN_GET}<strong> {strings.COINS_20}</strong> {strings.COINS_FOR_USERS_20}</p></div>
                </div>
            ); 
        
        
    }
}
export default WorkLandscape;