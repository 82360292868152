import { log } from "util";

export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = window.document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  };
export const setCookie = (name,value) => {
    var expires = "";
    var days = 9999
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    window.document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export const getRequestParams = () => {
        var requestParams = {
            'is_web': true,
            'is_vip': true
        }
       
        if (getCookie("login655") !== null && getCookie("login655") !== undefined) {
            requestParams.login = getCookie("login655")
            requestParams.user_id = getCookie("user_id")
        }
        if (getCookie("session_id") !== null && getCookie("session_id") !== undefined) {
            requestParams.sessionID = getCookie("session_id")
        }
        return requestParams;
    };


    export const getGoogleId = () => {
        return getCookie("google_user_id");
    };
    export const getSessionID = () => {
        return getCookie("session_id");
    };
    export const getPromoId = () => {
        return getCookie("promo_id");
    };
    export const getLogin = () => {
        return getCookie("login655");
    };
    export const setPromoId = (promo_id) => {
        setCookie('promo_id', promo_id)
    };
    export const setLogin = (login) => {
        setCookie('login655', login)
    };
    export const setShownHomeScreen = () => {
        setCookie('shownHomeScreen', 'shownHomeScreen')
    };
    export const isShownHomeScreen = () => {
        var shownHomeScreen = getCookie("shownHomeScreen")
        return (shownHomeScreen == 'shownHomeScreen')
    };

    export const setFirstFollowerCompleted = () => {
        setCookie('firstFollowerCompleted', 'firstFollowerCompleted')
    };
    export const isFirstFollowerCompleted = () => {
        var firstFollowerCompleted = getCookie("firstFollowerCompleted")
        return (firstFollowerCompleted == 'firstFollowerCompleted')
    };
    export const setFirstCommentCompleted = () => {
        setCookie('firstCommentCompleted', 'firstCommentCompleted')
    };
    export const isFirstCommentCompleted = () => {
        var firstCommentCompleted = getCookie("firstCommentCompleted")
        return (firstCommentCompleted == 'firstCommentCompleted')
    };

    export const setFirstTaskCompleted = () => {
        setCookie('firstTaskCompleted', 'firstTaskCompleted')
    };
    export const isFirstTaskCompleted = () => {
        var firstTaskCompleted = getCookie("firstTaskCompleted")
        return (firstTaskCompleted == 'firstTaskCompleted')
    };

    export const setSessionId = () => {
        if (getCookie("session_id") == null || getCookie("session_id") == undefined || getCookie("session_id") == "null" || getCookie("session_id") == "undefined") {
            setCookie('session_id', Date.now())
          }
    };
    export const saveRegistrCookie = (response, device_id, uniq_user_id, api_key) => {
        setCookie('device_id', device_id)
        setCookie('google_user_id', response.google_user_id)
        if (uniq_user_id != undefined && uniq_user_id != ""){
            setCookie('uniq_user_id', uniq_user_id)
        }
        if (api_key != undefined && api_key != ""){
            setCookie('api_key', api_key)
        }
        
    };
    export const saveInstaCookie = (login655, user_id, avatar) => {
        setCookie('login655', login655)
        setCookie('user_id', user_id)
        setCookie('avatar', avatar)
    };
    export const getInstaLogin = () => {
        return getCookie("login655");
    };
    export const getInstaAvatar = () => {
        return getCookie("avatar");
    };
    export const getUserId = () => {
        return getCookie("user_id");
    };
    export const getInstaLogins = () => {
        return JSON.parse(localStorage.getItem('loginsArray'))
    };
    export const getIsUpmobKey = () => {
        return getCookie("api_key") == "upmob" || getCookie("api_key") == "lexus";
    };
    export const addLogin = (login, avatar, user_id) => {
        // var separator = "&!&"
        // var logins = getCookie("logins354")
        // if (logins == undefined || logins == null || logins == ""){
        //     logins = login + "," + avatar + "," + user_id
        // } else if (!logins.includes((login + ","))){
        //     logins = logins + separator + login + "," + avatar + "," + user_id
        // }
        // setCookie('logins354', logins)
        var logins = getInstaLogins()
        if (logins == null || logins == undefined){
            logins = []
        }
        var userFound = false
        logins.forEach(user => {
            if (user.user_id == user_id){
                user.login = login
                user.avatar = avatar
                userFound = true
            }
        });
        if (!userFound){
            var loginJson = {login : login, avatar : avatar, user_id : user_id}
            logins.push(loginJson)
        }
        
        localStorage.setItem('loginsArray', JSON.stringify(logins));
    };
    export const updateLoginData = (login, avatar, user_id) => {
        var logins = getInstaLogins()
        if (logins == null || logins == undefined){
            logins = []
        }
        if (logins.length == 0){
            addLogin(login, avatar, user_id)
        } else {
            logins.forEach(user => {
                if (user.user_id == user_id){
                    user.avatar = avatar
                    user.login = login
                }
            });
        }
        localStorage.setItem('loginsArray', JSON.stringify(logins));
    }

