import React from 'react';
import '../loaders/loader.css'
class AuthLoader extends React.Component {
    constructor(props){
        super(props)

    }
    
    render(){
      
            return (
                        <div style={this.props.style != undefined ? this.props.style : {}} className="loader_auth">
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                
            ); 
        
        
    }
}
export default AuthLoader;
