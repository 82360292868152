/* eslint-disable eqeqeq */
import sn_insta from '../custom_views/custom_icons/sn_insta.svg'
import sn_yt from '../custom_views/custom_icons/sn_yt.svg'
import sn_vk from '../custom_views/custom_icons/sn_vk.svg'
import sn_tg from '../custom_views/custom_icons/sn_tg.svg'
import sn_tiktok from '../custom_views/custom_icons/sn_tiktok.svg'
import sn_h_tt from '../custom_views/custom_icons/sn_h_tt.svg'
import sn_h_yt from '../custom_views/custom_icons/sn_h_yt.svg'
import sn_h_vk from '../custom_views/custom_icons/sn_h_vk.svg'
import sn_h_insta from '../custom_views/custom_icons/sn_h_insta.svg'
import sn_h_tg from '../custom_views/custom_icons/sn_h_tg.svg'
import { declension } from './Utils'
export const arrowRotateStyle = () => {
    const arrowRotate = {
        transform: 'rotate(180deg)',
        marginTop: '6px'
    }
    return arrowRotate
}

export const formatSocialServices = (config, standartPrice) => {
    var c = {...config}
    var arrayTitles = []
    for (var k in config) {
        if (config.hasOwnProperty(k)) {
            var item = {}
            var mService = config[k]
            item.sn = k
            item.title = k
            item.img = config[k].icon
            mService.services.forEach(element => {
                element.is_hide = element.service == '10001' || element.service == '10002' || element.service == '10003' || element.service == '519' || element.service == '509' || element.service == '777'
                var price = standartPrice
                if (element.price != undefined){
                    price = Math.round(standartPrice * element.price)
                }
                element.title_price = (element.title ? element.title : element.name) + " (цена " + (element.service == "10002" ? 3 : element.price == undefined ? (standartPrice) : price) + " " + declension(price, "монета", "монеты", "монет") + " за 1 шт.)" 
                
            });
            
            arrayTitles.push(item)
        }
    }
    // arrayTitles[0].isSelected = true
    c.titles = arrayTitles
    return c
}

export const formatSocialConfig = (config, standartPrice) => {
    var c = {...config}
    for (var k in config) {
        if (config.hasOwnProperty(k)) {
            var innerConfig = config[k]
            var arr = []
            for (var key in innerConfig) {
                if (innerConfig.hasOwnProperty(key) && parseInt(key) < 10000) {
                    var item = innerConfig[key]
                    item.id = key
                    item.sn = k
                    item.is_auto_likes = key == '519' || key == "509"
                    item.is_poll = key == '777'
                    // const price = (item.price == undefined ? (standartPrice) : (standartPrice * item.price))
                    const price = (item.price == undefined ? (standartPrice) : (standartPrice * item.price))
                    item.title_price = item.title + " (цена " + price + " " + declension(price, "монета", "монеты", "монет") +  " за 1 шт.)"  
                    arr.push(item)
                    
                }
            }
            config[k].items = arr
        }
    }
    console.log("element = ", c)
    return c
}
export const getSnItems = () => {
    return [
            {index : 1, title : 'ВКонтакте', img : sn_vk, tag : 'VK', isSelected : true},
            {index : 2, title : 'Telegram', img : sn_tg, tag : 'TG'},
            {index : 3, title : 'TikTok', img : sn_tiktok, tag : 'TIK'},
            {index : 4, title : 'YouTube', img : sn_yt, tag : 'YOUTUBE'}
            ]
}
export function isValidUrl (urlString) {
	  	var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
	    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
	    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
	    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
	    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
	    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
	  return !!urlPattern.test(urlString);
}
export const getSocTitle = (title) => {
    if (title == SocialOrderType.INSTA){
        return 'Instagram'
    } else if (title == SocialOrderType.VK){
        return 'Вконтакте'
    } else if (title == SocialOrderType.TG){
        return 'Telegram'
    } else if (title == SocialOrderType.TIK){
        return 'Tik Tok'
    } else if (title == SocialOrderType.YOUTUBE){
        return 'Youtube'
    } else if (title == SocialOrderType.TV){
        return 'Трансляции'
    } else {
        return title
    }
}
export const getSocialHistoryIcon = (type) => {
    switch (type) {
        case SocialOrderType.INSTA:
            return sn_h_insta
        case SocialOrderType.TG:
            return sn_h_tg
        case SocialOrderType.TIK:
            return sn_h_tt
        case SocialOrderType.VK:
            return sn_h_vk
        case SocialOrderType.YOUTUBE:
            return sn_h_yt
        default:
            return sn_h_insta;
    }
}
export const SocialOrderType = {
    TG: "TG",
    VK: "VK",
    INSTA: "INSTA",
    TIK: "TIK",
    YOUTUBE: "YOUTUBE",
}